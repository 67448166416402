import { useGetDataPot } from "api/useGetDataPot";
import bigStar from "assets/bigStar.png";
import jackpotSparkle from "assets/jackpotSparkle.png";
import title from "assets/Jackpottitle.png";
import smallStar from "assets/lilStar.png";
import React, { useMemo } from "react";
import { numberWithCommas, useCountdown } from "utils";

const Countdown = ({ s }) => {
  const [days, hours, minutes, seconds] = useCountdown(s);
  return (
    <p
      className={
        "z-10 absolute  text-2xl font-semibold font-sans mt-32 md:mt-20 "
      }
    >
      {/*{days} : {hours} : {minutes} : {seconds}*/}
      {days} : {hours} : {minutes} : {seconds}
    </p>
  );
};

const JackpotCounter = () => {
  const { data } = useGetDataPot();
  const { diff_date, amount } = data || {};
  const s = useMemo(() => Math.floor(+diff_date || 0), [diff_date]);

  return (
    <div className="mt-10 relative max-w-[1268px] left-1/2 -translate-x-1/2">
      <img
        alt=""
        src={smallStar}
        className="right-3/4 mt-[-50px] absolute z-20 md:hidden"
      />
      <img
        alt=""
        src={title}
        className="z-10 absolute left-1/2 -translate-x-1/2 mt-[-30px] md:h-14"
      />
      <img
        alt=""
        src={bigStar}
        className="absolute z-20 right-10 top-[40px] md:hidden"
      />
      <div
        className="flex flex-col items-center justify-center  mt-[-100px]   h-[199px] relative mx-[6%] rounded-3xl  md:static md:mt-0 md:mb-8  overflow-hidden md:h-[120px] "
        style={{
          background: "linear-gradient(180deg, #FFE500 0%, #FFB700 100%)",
        }}
      >
        <img
          alt=""
          src={jackpotSparkle}
          className="absolute z-0   w-full md:hidden "
        />

        <div className="flex flex-1 justify-center items-center z-10 ">
          <p className="  text-4xl font-bold md:text-[32px]">
            {numberWithCommas(amount || 0)}₮
          </p>
          <Countdown s={s} />
        </div>
      </div>
    </div>
  );
};
export default JackpotCounter;
