import { apiClient } from "api/apiClient";

export const adminApis = {
  async login(data) {
    return (await apiClient.post("user/admin-login/", data))?.data;
  },
  async logout() {
    return (await apiClient.post("user/admin-logout/"))?.data;
  },
  async addBall(data) {
    return (await apiClient.post("lottery/add-ball/", data))?.data;
  },
  async getBalls() {
    return (await apiClient.get("prize/draw_number/"))?.data?.data;
  },
  async getJackpotData() {
    return (await apiClient.get("web/jackpot-info/"))?.data?.data;
  },
  async getJackpotHistory() {
    return (await apiClient.get("web/draw-history/"))?.data?.data;
  },
  async getLastDraw() {
    return (await apiClient.get("web/last-draw-info/"))?.data?.data;
  },
  async getTotalInfo() {
    return (await apiClient.get("web/total-won-info/"))?.data?.data;
  },
};

export const queryKeys = {
  JACKPOT_BALLS: "JACKPOT_BALLS",
  JACKPOT_DATA: "JACKPOT_DATA",
  JACKPOT_HISTORY: "JACKPOT_HISTORY",
  JACKPOT_LAST_DRAW: "JACKPOT_LAST_DRAW",
  JACKPOT_TOTAL_INFO: "JACKPOT_TOTAL_INFO",
  JACKPOT_MATCH_INFO: "JACKPOT_MATCH_INFO",
};
