import callLines from "assets/callLines.svg";
import delhii from "assets/delhiin-jishig.png";
import gmail from "assets/gmail.svg";
import lines from "assets/lines.svg";
import location from "assets/location.svg";
import phone from "assets/phone.svg";
import React from "react";
import {Helmet} from "react-helmet";

const Call = () => {
  return (
    <div className="mt-[93px]  z-0 bg-black10  overflow-y-scroll">
      <Helmet>
        <title>Megaball | Холбоо барих</title>
      </Helmet>
      <div className="flex bg-[#0048A6] justify-center  relative  z-0 overflow-hidden h-[100px] items-center ">
        <p className="font-bold text-4xl text-black0 md:text-[32px] text-center w-full">
          Холбоо барих
        </p>
        <img
          alt=""
          src={lines}
          className=" object-cover absolute right-0 bottom-0 pointer-events-none w-full md:hidden"
        />
      </div>
      <div className="bg-black10">
        <div className="relative overflow-hidden z-10 flex justify-center h-[600px] pt-32 md:flex-col h-full items-center">
          <img alt="" src={delhii} className="h-[300px] md:h-44" />
          <div className="bg-black0 rounded-3xl h-[250px] w-fit p-7 drop-shadow-xl max-w-[400px]  grid md:mx-8 md:my-8 h-full">
            <div className="flex border-solid border-x-0 border-t-0 border-y-[1px] border-black25 items-start ">
              <div className="flex ">
                <img alt="" src={location} className="h-5" />
                <p className="pl-3 font-sans text-[#414C58] ">
                  Монгол улс, Улаанбаатар хот,
                  <br /> Чингэлтэй дүүрэг, 1 хороо, Жуулчны
                  <br /> гудамж, Арт хаус центр, 5 давхар
                </p>
              </div>
            </div>
            <div className="flex border-solid border-x-0 border-t-0 border-y-[1px] border-black25 items-center  ">
              <img alt="" src={phone} className="h-5 " />
              <p className="pl-3 font-sans text-[#414C58] ">7755-2211</p>
            </div>
            <div className="flex items-center mt-4">
              <img alt="" src={gmail} className="h-5" />
              <p className="pl-3 font-sans text-[#414C58] ">contact@loto.mn</p>
            </div>
          </div>
        </div>
        <img
          alt=""
          src={callLines}
          className="absolute left-0  z-0 md:hidden"
        />
      </div>
    </div>
  );
};
export default Call;
