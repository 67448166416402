import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import faqlines from "assets/faqlines.svg";
import FaqCompMain from "components/FaqComps/FaqCompMain";
import { colors } from "constants/colors";
import React from "react";
import { Helmet } from "react-helmet";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Helmet>
        <title>Megaball | Түгээмэл асуулт</title>
      </Helmet>
      <img alt="" src={faqlines} className="absolute right-0" />
      {value === index && (
        <Box sx={{ p: 3, color: colors.blue }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function FAQ() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="bg-black10">
      <div className="flex bg-[#0048A6] mt-[93px] justify-center relative  z-0 overflow-hidden h-[100px] items-center ">
        <p className="font-bold text-4xl text-black0 text-center md:text-[32px] ">
          Түгээмэл асуулт, хариулт
        </p>
        <img
          alt=""
          src={faqlines}
          className=" object-cover absolute right-0 bottom-0 pointer-events-none"
        />
      </div>
      <div className="flex mx-40 md:mx-4 lg:hidden">
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            backgroundColor: colors.black10,
            display: "flex",
            marginTop: "93px",
            justifyContent: "between",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            style={{ width: "100%" }}
            onChange={handleChange}
            aria-label="Vertical tabs example"
          >
            <Tab label="Үндсэн" {...a11yProps(0)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <p className="font-bold mb-6 pl-4 text-3xl">Үндсэн</p>
            <FaqCompMain />
          </TabPanel>
          <TabPanel value={value} index={1}>
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three12312
          </TabPanel>
          <TabPanel value={value} index={3}>
            Item Four
          </TabPanel>
          <TabPanel value={value} index={4}>
            Item Five
          </TabPanel>
        </Box>
      </div>
      <div className="hidden lg:block mx-4 mt-12">
        <p className="font-bold mb-6 pl-4 text-blue text-2xl">Үндсэн</p>
        <FaqCompMain />
      </div>
    </div>
  );
}
