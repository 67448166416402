import homeMega from "assets/home-mega-ball.svg";
import lines from "assets/lines.svg";
import React from "react";

const MegaBallBanner = () => {
  return (
    <div className="flex bg-blue justify-center relative w-full py-[10%] sm:h-[20%]    ">
      <img
        alt=""
        src={homeMega}
        className="bg-blue justify-items-center pointer-events-none sm:w-[100%] z-10"
      />
      <div className="justify-items-end ">
        <img
          alt=""
          src={lines}
          className=" justify-items-end right-0 absolute bottom-0 h-[500px] pointer-events-none md:hidden z-0"
        />
      </div>
    </div>
  );
};
export default MegaBallBanner;
