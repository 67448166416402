import { ButtonBase } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
const MiniMenu = () => {
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  const history = useHistory();
  return (
    <div className="flex  gap-20 pl-52  md:pl-0 md:pt-12 ">
      <div className="flex flex-col justify-start gap-4 col-end-3">
        <p className=" text-[#FFD401] text-2xl ">Цэс</p>
        <ButtonBase
          sx={{ borderRadius: 24, justifyContent: "start" }}
          onClick={() => {
            history.push("/");
            topFunction();
          }}
        >
          <p className="text-xl  text-amber-50">Нүүр</p>
        </ButtonBase>
        <ButtonBase
          sx={{ borderRadius: 24, justifyContent: "start" }}
          onClick={() => {
            history.push("/about");
            topFunction();
          }}
        >
          <p className="text-xl text-amber-50 text-start">Бидний тухай</p>
        </ButtonBase>
        <ButtonBase
          sx={{ borderRadius: 24, justifyContent: "start" }}
          onClick={() => {
            history.push("/faq");
            topFunction();
          }}
        >
          <p className="text-xl text-amber-50">FAQ</p>
        </ButtonBase>
        <ButtonBase
          sx={{ borderRadius: 24, justifyContent: "start" }}
          onClick={() => {
            history.push("/rng");
            topFunction();
          }}
        >
          <p className="text-xl text-amber-50">RNG</p>
        </ButtonBase>
      </div>
      {/*<div>*/}
      {/*  <p className="text-[#FFD401] text-xl">Блог</p>*/}
      {/*</div>*/}
      <div className="flex flex-col justify-start gap-4">
        <p className="text-[#FFD401] text-2xl">Тусламж</p>
        <ButtonBase
          sx={{ borderRadius: 24, justifyContent: "start" }}
          onClick={() => history.push("/", { guide: true })}
        >
          <p className="text-xl text-amber-50 text-start">Хэрхэн тоглох вэ?</p>
        </ButtonBase>
        <ButtonBase
          sx={{ borderRadius: 24, justifyContent: "start" }}
          onClick={() => {
            history.push("/awards");
            topFunction();
          }}
        >
          <p className="text-xl text-amber-50 text-start">Шагналын сан</p>
        </ButtonBase>
      </div>
    </div>
  );
};
export default MiniMenu;
