import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { colors } from "constants/colors";
import * as React from "react";

export default function FaqCompMain() {
  const Accordion = styled((props) => {
    return <MuiAccordion disableGutters elevation={0} square {...props} />;
  })(({ theme }) => ({
    marginBottom: 24,
    borderRadius: 24,
    backgroundColor: "transparent",
    "&:before": {
      display: "none",
    },
  }));
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ color: colors.blue, fontWeight: 700 }}>
            Megaball гэж юу вэ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              backgroundColor: colors.black0,
              borderRadius: 8,
              textAlign: "start",
              color: colors.black70,
              padding: 4,
            }}
          >
            “Мegaball” нь америк стандартыг бүрэн хангасан Монголын анхны
            лотерей юм. Тодруулбал тус лотерейн тохирлын машинууд нь дэлхийн 130
            гаруй улс оронтой хамтран ажилладаг АНУ-ын “Smartplay” компанид
            үйлдвэрлэсэн. Мegaball-н ашиглаж буй Criterion II, Messenger нэртэй
            тохирлын машин нь АНУ-ын алдарт Мegamillions лотерейд ашиглагддаг.
            Мөн “Megaball” нь нийгэмд тустай Монголын анхны лотерей юм. Бид
            жилийн үйл ажиллагааны орлогоосоо 2 тэрбум төгрөгийг хүүхдийн эрүүл,
            мэнд боловсролд хандивлахаар төлөвлөсөн.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ color: colors.blue, fontWeight: 700 }}>
            Тохирол хэзээ явагдах вэ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              backgroundColor: colors.black0,
              borderRadius: 8,
              textAlign: "start",
              color: colors.black70,
              padding: 4,
            }}
          >
            “Megaball” лотерейн тохирол нь долоо хоног бүрийн Лхагва, Ням гарагт
            20 цаг 30 минутаас тогтмол орон даяар UBS, TV9 телевизээр шууд
            дамжуулагдан олон нийтэд хүрдэг.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ color: colors.blue, fontWeight: 700 }}>
            Тохиролыг хаанаас үзэх вэ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              backgroundColor: colors.black0,
              borderRadius: 8,
              textAlign: "start",
              color: colors.black70,
              padding: 4,
            }}
          >
            “Megaball” лотерейн тохирлыг долоо хоног бүрийн Лхагва, Ням гарагийн
            20 цаг 30 минутаас UBS, TV9 телевизээр орон даяар шууд дамжуулдаг.
            Мөн манай “Megaball” фэйсбүүк пэйжээр хүлээн авч үзэх боломжтой.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ color: colors.blue, fontWeight: 700 }}>
            Шагнал хожсон эсэхээ яаж мэдэх вэ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              backgroundColor: colors.black0,
              borderRadius: 8,
              textAlign: "start",
              color: colors.black70,
              padding: 4,
            }}
          >
            Та 9 төрлийн хонжвороос аль нэгийг нь хожсон бол таны гар утсан дахь
            “Hipay” аппликейшн руу тохирол явагдснаас хойш 30 минутын дотор
            мэдэгдэл очиж, хонжворын гүйлгээ хийгдэнэ.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ color: colors.blue, fontWeight: 700 }}>
            Лотерейн тасалбар хаанаас худалдаж авах вэ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              backgroundColor: colors.black0,
              borderRadius: 8,
              textAlign: "start",
              color: colors.black70,
              padding: 4,
            }}
          >
            Та гар утсандаа “Hipay” аппликейшн татаж авч, бүртгүүлээд “Megaball”
            лотерейн тасалбар худалдан авч оролцох боломжтой.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ color: colors.blue, fontWeight: 700 }}>
            Өөр улсаас оролцож болох уу?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              backgroundColor: colors.black0,
              borderRadius: 8,
              textAlign: "start",
              color: colors.black70,
              padding: 4,
            }}
          >
            Мэдээж болно. Учир нь цахим төлбөр тооцооны иж бүрэн, найдвартай эко
            системийг бүрдүүлсэн “Hipay” аппликейшн нь хил хязгаар харгалзахгүй
            танд хүрнэ. Та аппликейшнээ татаж аваад нийгэмд тустай, ТЭРБУМ
            төгрөгийн шагналын сантай Megaball лотерейд оролцох боломжтой.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ color: colors.blue, fontWeight: 700 }}>
            Хонжвор хожсон тохиолдолд хэрхэн авах вэ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              backgroundColor: colors.black0,
              borderRadius: 8,
              textAlign: "start",
              color: colors.black70,
              padding: 4,
            }}
          >
            Та 2.5 сая төгрөгөөс дээш мөнгөн дүнтэй хонжвор хожсон тохиолдолд
            “Megaball” ХХК-ийн байранд иргэний үнэмлэх, бичиг баримттайгаа ирж
            мөнгөн шагналаа авна. Харин 2.5 саяаас доош мөнгөн хонжвор хожсон
            бол таны “Hipay” дансанд 30 хүртэлх минутын дотор шилжин орно.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ color: colors.blue, fontWeight: 700 }}>
            Тасалбарын үнэ яагаад хоёр сонголттой байгаа вэ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              backgroundColor: colors.black0,
              borderRadius: 8,
              textAlign: "start",
              color: colors.black70,
              padding: 4,
            }}
          >
            Хонжворын үнийн дүнгээс шалтгаалан тасалбарын үнэ өөр өөр байх
            бөгөөд Та өөрийн тоглох тоогоо сонгон аль хонжворт азаа үзэхээ
            шийдэх боломжийг бид олгож байна. 5,000 төгрөгийн тасалбар авсанаар
            Та супер шагнал болох тэрбум төгрөг хожих боломжтой бол 1,000
            төгрөгийн тасалбар авсанаар 200,000,000 төгрөх хожих боломжтой юм.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ color: colors.blue, fontWeight: 700 }}>
            Megaball-тай холбоотой шинэ мэдээ, мэдээллийг хаанаас авах вэ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              backgroundColor: colors.black0,
              borderRadius: 8,
              textAlign: "start",
              color: colors.black70,
              padding: 4,
            }}
          >
            Та манай Megaball лотерейтэй холбоотой албан ёсны мэдээллийг манай
            албан ёсны дараах сошл сувгуудыг дагаснаар мэдээлэл авах боломжтой.
            <br />
            Facebook: https://www.facebook.com/Megaballmongolia <br />{" "}
            Instagram: https://www.youtube.com/@megaballmongolia
            <br /> YouTube: https://www.instagram.com/megaballmongolia/
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ color: colors.blue, fontWeight: 700 }}>
            Хэрвээ супер шагналын эзэн тодрохгүй бол яах вэ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              backgroundColor: colors.black0,
              borderRadius: 8,
              textAlign: "start",
              color: colors.black70,
              padding: 4,
            }}
          >
            Хэрэв супер шагнал болох Жекпот тухайн тохиролоор тодроогүй
            тохиолдолд борлуулалтын орлогоос хонжвор олгосны дараах дүнгээр
            ялагч тодрох хүртэл байнга өсөн нэмэгдэж байдаг. Жишээ нь: Жекпот
            1,000,000,000 байхад тухайн долоо хоногт 100 сая төгрөгийн
            борлуулалт хийгдсэн бол түүний 65% болох 65 сая төгрөгөөр Жекпот
            санг нэмэгдүүлэх ба уг тохиролд 30 сая төгрөгийн хонжворыг ялагчид
            тодорсон гэж үзвэл хожвор олгосны дараах буюу 35 сая төгрөгөөр
            нэмэгдэж Супер шагнал 1,035,000,000 төгрөг болно.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ color: colors.blue, fontWeight: 700 }}>
            Лотерей сугалаанаас юугаараа ялгаатай вэ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              backgroundColor: colors.black0,
              borderRadius: 8,
              textAlign: "start",
              color: colors.black70,
              padding: 4,
            }}
          >
            Сугалааны хувьд хэрэглэгчид өгөгдсөн тусгай тоо, дугаарт тохируулан
            эд, мөнгөний хонжвор санал болгож таны азыг та биш, хэн нэгэн таны
            өмнөөс сугалж, шийдвэрлэдэг хонжворт тоглоом юм. Харин лотерейн
            хувьд тоглогч өөрөө дурын тоогоо сонгож хүний үйл ажиллагаанаас үл
            хамаарсан санамсаргүй байдлаар азтан тодрох тохирол явагддаг. Өөрөөр
            хэлбэл олон улсын мэргэжлийн байгууллагаар санамсаргүй байдлын
            баталгаажуулалт хийсэн тохирлын машинаар лотерейн азтанг тодруулдаг.
            Лотерейн тохирлын машины санамсаргүй байдлын баталгаажуулалтыг олон
            улсын Gaming labs international байгууллага хариуцна.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ color: colors.blue, fontWeight: 700 }}>
            Megaball албан ёсны байдлыг хэн хянах вэ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              backgroundColor: colors.black0,
              borderRadius: 8,
              textAlign: "start",
              color: colors.black70,
              padding: 4,
            }}
          >
            Бид Америкийн Megamillions-н ашиглагддаг яг тэр тохирлын машиныг
            оруулж ирсэн бөгөөд санамсаргүй байдлын баталгаажуулалтыг олон улсын
            Gaming Labs International байгууллага хийдэг. Мөн тохиролын шууд
            нэвтрүүлэгт гурван төлөөлөл хөндлөнгийн ажиглагчаар оролцдог юм.
            Үүнд иргэний төлөөлөл, инженер, аудитын байгууллага багтдаг. Тэд
            тухайн өдрийн тохирол эхлэхээс 2-3 цагийн өмнө шууд нэвтрүүлэг гарах
            UBS телевизийн байранд ирж тохирлын машин, бөмбөлгүүдийг хэрхэн
            хадгалж байгаа, аюулгүй, найдвартай байдал зэрэгт хяналт тавьдаг
            байна. 24 цагийн хяналтын камертай өрөөнд 4 тохирлын машинууд байх
            бөгөөд, төхөөрөмж бүр дээр *А* *В* гэсэн тэмдэглэгээтэй байна. Мөн
            бөмбөлөгүүд ижил тэмдэглэгээтэй байна. Үүнийг сонгохдоо *А*, *В*
            гэсэн тэмдэглэгээтэй шоо шидэж шийднэ. Бөмбөлөгүүдийг мөн адил
            дээрхтэй ижил зарчмаар сонгодог байна.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
