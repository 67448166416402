import audit from "assets/audit.svg";
import briefcase from "assets/briefcase.svg";
import camera from "assets/camera.svg";
import tohirol from "assets/Group 345089.png";
import huuli from "assets/huuli.svg";
import iphone from "assets/iPhone 12 Pro.png";
import lines2 from "assets/lines2.svg";
import teaching from "assets/teaching.svg";
import AboutBanner from "components/AboutBanner/AboutBanner";
import React from "react";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div className="mt-[93px] ">
      <Helmet>
        <title>Megaball | Бидний тухай</title>
      </Helmet>
      <AboutBanner />
      <div className="text-center bg-black10 flex justify-center h-[600px] md:h-[450px]">
        <div className=" pb-14 h-full max-w-[1750px]">
          <p className="text-black55 py-8 ">
            Монгол Улсад анх удаагаа 1,000,000,000{" "}
            <br className="hidden md:block" />
            /Нэг тэрбум/ төгрөгийн шагналын сантай{" "}
            <br className="hidden md:block" /> эд мөнгөний
            <br className="md:hidden" /> онлайн хонжворт лотерейг{" "}
            <br className="hidden md:block" /> “HiPay” аппликейшн дээр мини-апп{" "}
            <br className="hidden md:block" />
            хэлбэрээр нэвтрүүллээ.
          </p>
          <div className="aspect-w-10 aspect-h-6  md:aspect-h-3 md:aspect-w-4 md:mb-4">
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
              src="https://www.youtube.com/embed/vdUAlEyb_Gg"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture "
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden">
        <img
          alt=""
          src={lines2}
          className="absolute bottom-0 right-0 pointer-events-none "
        />
        <div className="flex justify-center">
          <div className="max-w-[1268px] ">
            <p className="text-3xl py-12  font-bold text-center">Ил тод</p>
            <div className="grid grid-cols-3 gap-20 px-8   mb-14 md:mb-5 md:grid-cols-2 sm:flex sm:flex-col ">
              <div className="flex ">
                <img alt="" src={tohirol} className="h-8 " />

                <div className="ml-5">
                  <p className="font-bold text-lg">Тохирлын машин</p>
                  <p className="mt-5 text-black55">
                    Санамсаргүй байдлын баталгаажуулалт хийгдсэн
                  </p>
                </div>
              </div>
              <div className="flex">
                <img alt="" src={audit} className="h-8" />
                <div className="ml-5">
                  <p className="font-bold text-lg">Аудитын компани</p>
                  <p className="mt-5 text-black55">Шонхор үнэлгээ аудит</p>
                </div>
              </div>
              <div className="flex md:my-3">
                <img alt="" src={huuli} className="h-8" />
                <div className="ml-5">
                  <p className="font-bold text-lg">Хуулийн фирм</p>
                  <p className="mt-5 text-black55">MDS & KhanLex</p>
                </div>
              </div>
              <div className="flex md:my-3 hidden md:block">
                <img alt="" src={teaching} className="h-8 " />
                <div className="ml-5">
                  <p className="font-bold text-lg">Ухаалгаар тоглох зөвлөгөө</p>
                  <p className="mt-5 text-black55">
                    Лотерейд оролцох лимит, Үнэ төлбөргүй тусламж, зөвөлгөө,
                    сэтгэл зүйч
                  </p>
                </div>
              </div>

              <div className="flex">
                <img alt="" src={camera} className="h-8" />
                <div className="ml-5">
                  <p className="font-bold text-lg">Шууд тохирол</p>
                  <p className="mt-5 text-black55">
                    UBS, Tv9 Лхагва, Ням гарагуудад 20:30 цагаас
                  </p>
                </div>
              </div>
              <div className="flex">
                <img alt="" src={briefcase} className="h-8" />
                <div className="ml-5">
                  <p className="font-bold text-lg">Татвар</p>
                  <p className="mt-5 text-black55">
                    Хонжворын үнийн дүнгээс үл хамааран хувь хүнд ногдох 40%
                    татварыг төлнө
                  </p>
                </div>
              </div>
              <div className="flex md:hidden">
                <img alt="" src={teaching} className="h-8" />
                <div className="ml-5">
                  <p className="font-bold text-lg">Ухаалгаар тоглох зөвлөгөө</p>
                  <p className="mt-5 text-black55">
                    Лотерейд оролцох лимит, Үнэ төлбөргүй тусламж, зөвөлгөө,
                    сэтгэл зүйч
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center bg-black10 pt-14">
        <img alt="" src={iphone} className="h-full" />
      </div>
      <div className="flex bg-black10 justify-center">
        <div className="max-w-[1750px]">
          <p className="text-center text-black55 bg-black10  py-8 md:mx-8">
            HiPay нь Монголбанкны төлбөрийн карт хүлээн авах тусгай
            зөвшөөрөлтэй, төлбөрийн картын <br className="block md:hidden" />
            системийн мэдээллийн аюулгүй байдлын олон улсын PCI DSS стандартын
            шаардлагыг <br className="block md:hidden" />
            бүрэнхангаж, баталгаажсан бөгөөд Монголчуудын уламжлалт хэрэглээг
            халж, хялбар, үр
            <br className="block md:hidden" /> ашигтай,иргэдийн хайртай
            үйлчилгээг бий болгохоор ажиллаж байна.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
