import CommonQ from "components/CommonQ/CommonQ";
import Guide from "components/Guide/Guide";
import JackpotCounter from "components/JackpotCounter/JackpotCounter";
import JackpotData from "components/JackpotData/JackpotData";
import MegaBallBanner from "components/MegaBallBanner/MegaballBanner";
import WhyMegaball from "components/WhyMegaball/WhyMegaball";
import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
const Home = () => {
  const { state } = useLocation();
  const guideRef = useRef();

  useEffect(() => {
    if (state?.guide === true) {
      guideRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [state]);
  return (
    <div className="z-0 mt-[93px] w-screen">
      <Helmet>
        <title>Megaball | Нүүр</title>
      </Helmet>
      <MegaBallBanner />
      <JackpotCounter />
      <JackpotData />
      <WhyMegaball />
      <div className="relative z-10">
        <Guide ref={guideRef} />
        <CommonQ />
      </div>
    </div>
  );
};

export default Home;
