import homeMega from "assets/home-mega-ball.svg";
import lines from "assets/lines.svg";
import React from "react";

const AboutBanner = () => {
  return (
    <div className="flex bg-[#0048A6] justify-center relative  z-0 overflow-hidden h-[100px] items-center ">
      <p className="font-bold text-4xl text-black0 text-center md:text-[32px]">
        Бидний тухай
      </p>
      <img
        alt=""
        src={lines}
        className=" object-cover absolute right-0 bottom-0 pointer-events-none"
      />
    </div>
  );
};
export default AboutBanner;
