import Footer from "components/Footer";
import Header from "components/Header";
import SideMenu from "components/SideMenu";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { renderRoutes } from "react-router-config";

const MainLayout = ({ route: { routes }, location }) => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location?.pathname]);
  return (
    <div className="flex flex-col min-h-screen overflow-y-scroll justify-between">
      <Header />
      {renderRoutes(routes)}
      <SideMenu />
      <Footer />
    </div>
  );
};

export default MainLayout;
