import guide1 from "assets/guides/1.png";
import guide2 from "assets/guides/2.png";
import guide3 from "assets/guides/3.png";
import guide4 from "assets/guides/4.png";
import guide5 from "assets/guides/5.png";
import guide6 from "assets/guides/6.png";
import guide7 from "assets/guides/7.png";
import guide8 from "assets/guides/8.png";
import guide9 from "assets/guides/9.png";
import guide10 from "assets/guides/10.png";
import React from "react";

const Slider = ({ activeIndex }) => {
  const images = [
    guide1,
    guide2,
    guide3,
    guide4,
    guide5,
    guide6,
    guide7,
    guide8,
    guide9,
    guide10
  ];
  return (
    <div className="relative max-w-[284px] z-10">
      <img
        alt=""
        src={images[activeIndex % images.length]}
        className="z-0 w-full"
      />
    </div>
  );
};

export default Slider;
