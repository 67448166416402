import React from "react";

export const Line = () => {
  return <div className="w-full   bg-black h-[1px]" />;
};

const Organs = () => {
  return <div></div>;
};
export const Dotted = () => {
  return <div className="w-full bg-green-200 border-dashed h-[0px] " />;
};

export default Organs;
