import { useMediaQuery } from "@mui/material";
import { useGetDataPot } from "api/useGetDataPot";
import { useGetLastDraw } from "api/useGetLastDraw";
import { useGetPotHistory } from "api/useGetPotHistory";
import { useGetTotalInfo } from "api/useGetTotalInfo";
import megaball from "assets/megaball.svg";
import notes from "assets/notes.svg";
import star from "assets/star.svg";
import BallItem from "components/BallItem";
import React from "react";
import { numberWithCommas } from "utils";

const JackpotData = () => {
  const { data } = useGetDataPot() || {};
  const { data: lastDraw } = useGetLastDraw() || {};
  const { data: totalInfo } = useGetTotalInfo() || {};
  const { total_amount, total_user_count } = totalInfo || {};
  const { start_date, amount } = data || {};
  const { post_date, total_count, lottery_numbers } = lastDraw || {};
  const t = new Date(post_date);
  const numArr = lottery_numbers?.split(",") || [];
  let month =
    t.getMonth() + 1 < 10 ? "0" + (t.getMonth() + 1) : t.getMonth() + 1;
  let dayNumber = t.getDate() < 10 ? "0" + t.getDate() : t.getDate();
  const date = t.getFullYear() + "." + month + "." + dayNumber;
  const day = t.getDay() - 1;
  const days = ["Даваа", "Мягмар", "Лхагва", "Пүрэв", "Баасан", "Бямба", "Ням"];

  const isPhone = useMediaQuery("(max-width: 768px)");

  return (
    <div className="w-full flex justify-center px-6">
      <div className="flex flex-1 justify-between mt-16 z-10 mb-[400px] max-w-[1268px] md:mb-14 md:mt-0 md:flex-col gap-4">
        <div className="justify-center items-center flex flex-1 flex-col">
          <img alt="" src={notes} className=" w-[55px]" />
          <p className="uppercase text-3xl font-bold mt-8">өмнөх тохирол</p>
          <p className="text-[#FF5C00] text-lg text-center my-4">
            {date} | {days[day]} гараг
          </p>
          <div className="bg-[#183C72] flex justify-center text-[24px] rounded-2xl text-[#183C72] flex gap-4 p-5 w-full">
            {numArr?.map((item, index) => (
              <BallItem
                key={index}
                ball={item}
                size={isPhone ? 32 : 58}
                isMegaBall={index === 5}
              />
            ))}
          </div>
          {/*<div>{lastHistory}</div>*/}
        </div>
        <div className="justify-center items-center flex flex-1 flex-col">
          <img alt="" src={star} className="" />
          <p className="uppercase font-bold text-center text-3xl mt-8">
            Нийт хонжвор
          </p>
          <p className="text-[#FF5C00] text-lg text-center my-4">
            {numberWithCommas(total_user_count || 0)} азтан
          </p>
          <div className="bg-orange flex justify-center rounded-2xl p-5 w-full">
            <p className="text-black0 font-bold text-[48px] md:text-[24px]">
              {numberWithCommas(total_amount || 0)}₮
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JackpotData;
