import idq from "assets/idq.svg";
import delhii from "assets/image 207.png";
import hurteemj from "assets/image 208 (1).png";
import hariutslaga from "assets/image 209.png";
import ayulgui from "assets/image 211 (1).png";
import mds from "assets/image 212.png";
import smartpay from "assets/image 213.png";
import shuvuu from "assets/Layer_x0020_1.png";
import megacircle from "assets/megacircle.svg";
import hipay from "assets/Union 1.png";
import React from "react";

const Item = ({ src, text, title }) => {
  return (
    <div className="rounded-xl bg-black0 drop-shadow-xl">
      <img alt="" src={src} className="h-20 mt-5" />
      <div>
        <p className="text-xl font-bold my-4">{title}</p>
        <p className="mb-12 text-xs mx-6 text-black85">{text}</p>
      </div>
    </div>
  );
};

const WhyMegaball = () => {
  return (
    <div className="flex w-full justify-center px-8 absolute mt-[-280px] md:static md:mt-0 md:mb-14">
      <div className="flex bg-[#EDF3FF] z-20 relative justify-center rounded-[32px] flex-col text-center max-w-[1250px]">
        <img
          alt=""
          src={megacircle}
          className="h-[40px] absolute -top-[20px] z-30 left-1/2 -translate-x-1/2"
        />
        <p className="font-bold text-3xl mb-8 mt-12 sm:text-xl">
          Яагаад MEGABALL гэж
        </p>
        <div className="grid-cols-4 grid gap-4 md:grid-cols-1 mb-12 px-6">
          <Item
            src={delhii}
            title="Дэлхийн жишиг"
            text="Монголд анх удаа нэг тэрбум төгрөгийн шагналын сантай, америк
                стандартыг бүрэн хангасан онлайн лотерейг нэвтрүүлэв"
          />
          <Item
            src={ayulgui}
            title="Аюулгүй систем"
            text="Санамсаргүй байдлын сертификаттай Smartplay компанийн Criterion
                II төхөөрөмжийг ашиглан шударгаар ялагчийг тодруулна"
          />
          <Item
            src={hariutslaga}
            title="Хариуцлагатай"
            text="Бид тасалбар худалдан авах хязгаар тогтоож, хэрэглээнээсээ
                хэтэрсэн худалдан авалт хийхээс таныг хамгаална"
          />
          <Item
            src={hurteemj}
            title="Хүртээмжтэй"
            text="Нийт орлогын 50 хувийг хүүхдийн эрүүл мэндийг хамгаалахад
                зориулж, айл өрхөд аз жаргал бэлэглэнэ"
          />
        </div>

        <div className="md:hidden">
          <p className="font-bold text-xl ">Хамтрагч байгууллагууд</p>
          <div className="flex justify-between mx-28 my-2 align-middle items-center">
            <img alt="" src={mds} className="h-8 mix-blend-luminosity flex" />
            <img alt="" src={hipay} className="h-8 mix-blend-luminosity" />
            <img alt="" src={smartpay} className="h-6 mix-blend-luminosity" />
            <img alt="" src={shuvuu} className="mix-blend-luminosity" />
            <img alt="" src={idq} className="h-12 mix-blend-luminosity" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default WhyMegaball;
