import { useMediaQuery } from "@mui/material";
import mega from "assets/megacircle.svg";
import { colors } from "constants/colors";
import React from "react";
import { numberWithCommas, numberWithSpace } from "utils";

const NormalBall = () => {
  return (
    <div
      className="w-[40px] aspect-1 rounded-full"
      style={{
        background:
          "radial-gradient(41.3% 41.3% at 43.48% 43.48%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(41.3% 41.3% at 43.48% 43.48%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #CDCDCD",
      }}
    />
  );
};

const MegaBall = () => {
  return <img alt="" src={mega} className="w-[40px] " />;
};
const NormalBallofPhone = () => {
  return (
    <div
      className="w-[40px] aspect-1 rounded-full"
      style={{
        background:
          "radial-gradient(41.3% 41.3% at 43.48% 43.48%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(41.3% 41.3% at 43.48% 43.48%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #CDCDCD",
      }}
    />
  );
};

const JackpotBall = ({ count = 0, color, price }) => {
  return (
    <div className="flex flex-col items-center ">
      <p className="text-[24px] font-semibold text-black70 mb-2">
        {numberWithCommas(price)}₮
      </p>
      <div
        className="w-[57px] aspect-1 rounded-full flex items-center justify-center relative"
        style={{
          background:
            count > 0
              ? "linear-gradient(180deg, #F7C041 0%, #FCEF50 100%)"
              : "white",
        }}
      >
        <div
          className="absolute w-[45px] aspect-1 rounded-full"
          style={{
            borderWidth: 3,
            borderStyle: "solid",
            borderColor: colors.black70,
          }}
        />
        <p className="text-[32px] font-bold absolute" style={{ color }}>
          {count}
        </p>
      </div>
    </div>
  );
};

const PrizeInfo = ({ item = {}, data = [] }) => {
  const { balls = {}, prize_name, alt_name } = item;
  const { normal, mega } = balls;

  const info =
    data?.find(
      (c) =>
        c.prize_name === prize_name || (alt_name && c.prize_name === alt_name)
    ) || {};

  const { total_count = 0, total_amount = 0 } = info;

  const isJackpot = prize_name === "PRIZE_JACKPOT";
  const jackpotData = data?.find((c) => !!c.JACKPOT_DATA)?.JACKPOT_DATA || [];
  const sortedJackpotData = [...jackpotData].sort((a, b) => b.price - a.price);
  const jackpotTotal = jackpotData?.reduce(
    (acc, curr) => acc + (+curr?.total_amount || 0),
    0
  );
  const isPhone = useMediaQuery("(max-width: 768px)");
  return (
    <div className="flex flex-row items-center py-4 z-10 border-x-0 border-solid border-y-[1px] border-t-0 border-black40">
      <div className="flex flex-row w-[308px] gap-3 md:hidden ">
        {Array.from({ length: normal }, (item, index) => (
          <NormalBall key={index} />
        ))}
        {Array.from({ length: mega }, (item, index) => (
          <MegaBall key={index} />
        ))}
      </div>
      <div className="flex flex-row w-[100px] gap-3 hidden md:flex">
        <div
          className="w-[40px] aspect-1 rounded-full flex items-center justify-center relative"
          style={{
            background:
              "radial-gradient(41.3% 41.3% at 43.48% 43.48%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(41.3% 41.3% at 43.48% 43.48%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #CDCDCD",
          }}
        >
          {normal}
        </div>

        {Array.from({ length: mega }, (item, index) => (
          <MegaBall key={index} />
        ))}
      </div>
      <div className="flex flex-1 flex-row items-center justify-center gap-8 ">
        {isJackpot ? (
          <>
            <div className="hidden md:block">
              <p className="text-2xl font-semibold text-black70 ">
                {jackpotTotal}
              </p>
            </div>
            <div className="flex gap-8 md:hidden">
              {sortedJackpotData.map((item, index) => (
                <JackpotBall
                  key={index}
                  count={
                    sortedJackpotData?.find((c) => c.price === item.price)
                      ?.total_count
                  }
                  color={
                    (item.price === 5000 && "#003399") ||
                    (item.price === 2500 && "#FF3B63") ||
                    (item.price === 1000 && "#FF3B63") ||
                    (item.price === 500 && "#623393")
                  }
                  price={item.price}
                />
              ))}
              {/*<JackpotBall*/}
              {/*  price={5000}*/}
              {/*  count={jackpotData?.find((c) => c.price === 5000)?.total_count}*/}
              {/*  color="#003399"*/}
              {/*/>*/}
              {/*<JackpotBall*/}
              {/*  price={2500}*/}
              {/*  count={jackpotData?.find((c) => c.price === 2500)?.total_count}*/}
              {/*  color="#FF3B63"*/}
              {/*/>*/}
              {/*<JackpotBall*/}
              {/*  price={500}*/}
              {/*  count={jackpotData?.find((c) => c.price === 500)?.total_count}*/}
              {/*  color="#623393"*/}
              {/*/>*/}
            </div>
          </>
        ) : (
          <p className="text-center text-[#003399] text-[36px] md:text-[24px] md:text-black70 font-semibold">
            {total_count}
          </p>
        )}
      </div>
      <div
        className="rounded-[51px] border-solid border border-black40 flex md:border-0 md:rounded-[0px] items-center justify-center "
        style={{
          marginRight: isPhone ? 0 : isJackpot ? -20 : 18,
          marginLeft: isPhone ? 0 : isJackpot ? 44 : 44,
          width: isPhone ? 100 : isJackpot ? 280 : 210,
          height: isJackpot ? 52 : 40,
          background: isPhone
            ? "transparent"
            : isJackpot
            ? "linear-gradient(180deg, #F6BF40 0%, #FCF150 100%)"
            : "linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%)",
        }}
      >
        <p className="text-center text-[#1C459F] text-[24px] md:text-black70  font-semibold md:text-[16px]">
          {numberWithSpace(isJackpot ? jackpotTotal : total_amount)}₮
        </p>
      </div>
    </div>
  );
};

export default PrizeInfo;
