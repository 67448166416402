import ballguide from "assets/Asset 1@2x 3.png";
import awardPrice from "assets/awardPrice1.png";
import balls from "assets/balls.png";
import contract from "assets/contract.svg";
import awards3 from "assets/Frame 427321213.png";
import lines from "assets/lines.svg";
import React from "react";
import { Helmet } from "react-helmet";

const Awards = () => {
  return (
    <div className="mt-[93px] z-0">
      <Helmet>
        <title>Megaball | Шагналын сан</title>
      </Helmet>
      <div className="flex bg-[#0048A6] justify-center relative  z-0 overflow-hidden h-[100px] items-center ">
        <p className="font-bold text-4xl text-black0 text-center md:text-[32px] ">
          Шагналын сан
        </p>
        <img
          alt=""
          src={lines}
          className=" object-cover absolute right-0 bottom-0 pointer-events-none"
        />
      </div>
      <div className="bg-black10 py-16 relative flex justify-center ">
        <div className="flex md:flex-col ">
          <div className="  hidden md:block">
            <p className="text-center text-xl text-black70 font-bold">
              Хонжворын дүн
            </p>
          </div>
          <div className="flex items-end justify-center relative pt-8 pr-4 overflow-hidden md:hidden">
            <img alt="" src={balls} className="h-[500px]" />
          </div>

          <div className="flex flex-col justify-center relative pt-8">
            <div className="flex-row flex">
              <img
                alt=""
                src={ballguide}
                className=" z-10 pointer-events-none hidden md:block md:h-[300px] mt-8"
              />
              <div>
                <div className="flex flex-row justify-around flex-1 mb-2">
                  <p className="text-xl font-bold text-black70 ">5,000₮</p>
                  <p className="text-xl font-bold text-black70 ">1000₮</p>
                </div>
                <img
                  alt=""
                  src={awardPrice}
                  className="relative z-10 pointer-events-none h-[500px] md:h-[300px]"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-end ml-10 md:h-80 md:ml-0 md:mt-10 relative md:items-center">
            <p className="text-center text-xl pb-3 font-bold md:mt-6  text-black70">
              Хонжворын магадлал
            </p>
            <div className="hidden md:block flex items-center justify-center ">
              <img
                alt=""
                src={ballguide}
                className=" z-10 pointer-events-none "
              />
              <img
                alt=""
                src={awards3}
                className=" z-10 h-[300px] pointer-events-none"
              />
            </div>
            <img
              alt=""
              src={awards3}
              className="relative z-10 h-[500px] w-fit ml-8 pointer-events-none md:hidden"
            />
          </div>
        </div>
        <img
          alt=""
          src={contract}
          className="absolute pointer-events-none z-0 left-0 top-0 pointer-events-none md:hidden"
        />
      </div>
    </div>
  );
};

export default Awards;
