import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { renderRoutes } from "react-router-config";
import { BrowserRouter as Router } from "react-router-dom";
import { MainRoutes } from "router";
const TRACKING_ID = "G-X49N6QVJ6Y";
ReactGA.initialize(TRACKING_ID);
function App() {
  return <Router>{renderRoutes(MainRoutes)}</Router>;
}

export default App;
