import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import contract from "assets/contract.svg";
import lines from "assets/lines.svg";
import React from "react";
import { Helmet } from "react-helmet";

export function createData({ name, price1, price2, price3 }) {
  return { name, price1, price2, price3 };
}
const rows = [
  {
    name: "Жекпот шагнал /Тухайн үед зарлагдсан дүн/",
    price1: "1,000,000,000₮",
    price2: "200,000,000₮",
  },
  {
    name: "Хоёрдугаар шагнал",
    price1: "100,000,000₮",
    price2: "20,000,000₮",
  },
  {
    name: "Гуравдугаар шагнал",
    price1: "25,000,000₮",
    price2: "5,000,000₮",
  },
  {
    name: "Дөрөвдүгээр шагнал",
    price1: "350,000₮",
    price2: "70,000₮",
  },
  {
    name: "Тавдугаар шагнал",
    price1: "150,000₮",
    price2: "30,000₮",
  },
  {
    name: "Зургаадугаар шагнал",
    price1: "20,000₮",
    price2: "4,000₮",
  },
  {
    name: "Долоодугаар шагнал",
    price1: "15,000₮",
    price2: "3,000₮",
  },
  {
    name: "Наймдугаар шагнал /Үнэгүй тасалбар/",
    price1: "15,000₮",
    price2: "3,000₮",
  },
  {
    name: "Есдүгээр шагнал /Үнэгүй тасалбар/",
    price1: "10,000₮",
    price2: "2,000₮",
  },
];
const Contract = () => {
  return (
    <div className="mt-[93px] z-0  ">
      <Helmet>
        <title>Megaball | Үйлчилгээний нөхцөл</title>
      </Helmet>
      <div className="flex bg-[#0048A6] justify-center relative  z-0 overflow-hidden h-[100px] items-center ">
        <p className="font-bold text-4xl text-black0 text-center md:text-[32px] ">
          Үйлчилгээний нөхцөл
        </p>
        <img
          alt=""
          src={lines}
          className=" object-cover absolute right-0 bottom-0 pointer-events-none md:hidden"
        />
      </div>
      <div className="flex justify-center mt-12 mx-8">
        <div className="max-w-[1268px] ">
          <p className="text-3xl font-semibold text-center md:text-[24px]">
            “MEGABALL” ЛОТЕРЕЙН ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ
          </p>
          <p className="text-center my-6 text-2xl font-medium md:text-xl">
            {" "}
            1. Нийтлэг үндэслэл
          </p>
          <div className="flex">
            <p>
              1.1. Энэхүү Үйлчилгээний нөхцөл (цаашид “Үйлчилгээний нөхцөл”
              гэх)-өөр нэг талаас Монгол Улсад эд мөнгөний хонжворт сугалаа
              гаргах тусгай зөвшөөрөлтэй “Мегаболл” ХХК-ийн www.loto.mn, “Хай
              Пэймэнт Солюшнс” ХХК -ийн “HiPay” аппликейшн дахь мини апп
              “Megaball” лотерейд бүртгүүлж, ашиглаж буй хэрэглэгч (цаашид
              “Тоглогч” гэх) нөгөө талаас “Мегаболл” ХХК (цаашид “Компани”,
              хамтад нь “Талууд” гэх)-ийн хооронд үүсэх Тоглогч “Megaball”
              лотерейд оролцох, тасалбар худалдах, худалдан авах, тохиролд
              оролцох, хонжвор, түүнийг төлөхтэй холбоотой үүсэх харилцааг
              зохицуулна.{" "}
            </p>
          </div>
          <p>
            1.2. Энэхүү үйлчилгээний нөхцөл нь зөвхөн “Megaball” лотерей /цаашид
            “Лотерей” гэх/-д хамаарна.{" "}
          </p>
          <p>
            1.3. Талууд энэхүү үйлчилгээний нөхцөлийн 1.2-д заасан лотерейны
            төлбөрийг “Хай Пэймэнт Солюшнс” ХХК -ийн “HiPay” аппликейшнээр
            дамжуулан төлнө.
          </p>
          <p className="text-center my-6 text-2xl font-medium">
            {" "}
            2. Лотерейнд оролцох
          </p>
          <p>
            2.1. Дараах шалгуурыг бүрэн хангаснаар Лотерейнд оролцох боломжтой
            болно. Үүнд:
            <br />{" "}
            <span className="ml-7 ">
              2.1.1. “HiPay” аппликейшны хэрэглэгч нь энэхүү үйлчилгээний
              нөхцөлтэй танилцаж зөвшөөрөх товч дарсан байх;
            </span>
            <br />
            <span className="ml-7">
              2.1.2. “HiPay” аппликейшнд бүртгэлтэй зөвхөн 18 насанд хүрсэн
              Монгол Улсын иргэн байх;
            </span>
            <br />
            <span className="ml-7 ">
              2.1.3. Лотерейн тасалбар, хонжворын мэдээлэлтэй танилцсанаар
              тасалбар худалдан авсан байх. Үүнд:
            </span>
            <br />
            <span className="ml-8 ">
              2.1.3.1. Хэрэглэгч өөрөө тухай бүр худалдан авалт хийх,
            </span>
            <br />
            <span className="ml-8 ">
              2.1.3.2. “HiPay” аппликейшнд холбосон картаараа тогтмол худалдан
              авалт хийх,
            </span>
            <br />
            <span className="ml-7 ">
              2.1.4. Нэг удаагийн лотерейн тохирлоор худалдан авах нийт
              тасалбарын үнэ 200,000 төгрөгөөс хэтрээгүй байх.
            </span>
            <br /> 2.2. Лотерейн тохирлыг долоо хоног бүрийн Лхагва, Ням
            гарагийн орой 20:30 цагаас 21:00 хооронд явуулна. Хэрэв давагдашгүй
            хүчин зүйлийн улмаас тохирол хугацаандаа явагдах боломжгүй болсон
            тохиолдолд Компани тохирлын товыг өөрчилж болно. <br />
            2.3. Тасалбар худалдан авах эрх нь лотерейн тохирол явагдсан өдрийн
            21:00 (GMT+8) цагаас дараагийн тохирол явагдах өдрийн 20:00 (GMT+8)
            цаг хүртэл тогтмол нээлттэй байна. Лотерейн оролцогч нь дараах бүрэн
            эрхтэй. Үүнд: <br />
            <span className="ml-7 ">
              2.3.1. Худалдан авсан тасалбарын дугааруудыг харах;{" "}
            </span>{" "}
            <br />
            <span className="ml-7 ">
              2.3.2. Тохирлын мэдээлэлтэй танилцах;
            </span>{" "}
            <br />
            <span className="ml-7 ">
              2.3.3. Өөрийн хожсон хонжворын мэдээлэлтэй танилцах;
            </span>{" "}
            <br />
            <span className="ml-7 ">
              2.3.4. Өөрийн хожсон хонжвороо Компаниас шаардах, хүлээн авах.
            </span>
            <p className="text-center my-6 text-2xl font-medium">
              {" "}
              3. Лотерейн хонжвор
            </p>
            <p className="mb-8">
              3.1. Тоглогч нь нэг удаагийн тохирлоор зургаан /6/ тооны хослол
              бүхий хэд хэдэн тасалбар худалдах авах боломжтой бөгөөд үндсэн
              багцын 5-н тоог 1-45 хүртэлх дугаарын хооронд, азын 1 тоог 1-5
              хүртэлх дугаарын хооронд сонгоно.
              <br /> 3.2. Тоглогч нь нэг буюу хэд хэдэн тасалбар худалдан
              авснаар лотерейнд оролцох боломжтой болно.
              <br /> 3.3. Тоглогчийн худалдан авсан тасалбарын үнийн дүнгээс
              хамааран тохирлын шагналын хэмжээ дараах байдлаар байна. Үүнд:
            </p>
          </p>

          <div className="sm:hidden">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 450 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Төрөл</TableCell>
                    <TableCell />
                    <TableCell>Шагналын хэмжээ</TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell>Нэгж тасалбарын үнэ</TableCell>
                    <TableCell align="right">5,000₮</TableCell>
                    <TableCell align="right">1,000₮</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.price1}</TableCell>
                      <TableCell align="right">{row.price2}</TableCell>
                      <TableCell align="right">{row.price3}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="mt-8">
            3.4. Компани нь Тасалбар худалдан авалт тутамд Hipay аппликейшнээр
            дамжуулан e-barimt олгоно.
            <br />
            3.5. Тасалбарыг аливаа тохиолдолд буцаах боломжгүй байна.
            <br />
            3.6. Тохиролд Компани болон Хай пэймэнт солюшнс ХХК-ийн ажилтан
            оролцох боломжтой бөгөөд Жекпот болон хоёрдугаар шагналаас бусад
            шагналыг хожих эрхтэй байна.
            <br />
            <p className="text-center text-2xl font-medium my-6">
              4. Жекпот шагнал
            </p>
            <br />
            4.1. Жекпот шагналыг тоглогчийн сонгосон үндсэн багцын бүх таван /5/
            тоо, азын нэг /1/ тоо нийт зургаан /6/ тооны хослолын үндсэн дээр
            олгоно.
            <br />
            4.2. Жекпот шагналын эзэн тодроогүй тохиолдолд нэгж тасалбарын үнээс
            хамааран Жекпот шагналын хэмжээ өмнөх тохирлын борлуулалтын орлогын
            16 хувьтай тэнцэх хэмжээгээр нэмэгдэнэ.
            <br />
            4.3. Тохирлоор 1,000 төгрөгөөс бусад үнийн дүнтэй тасалбарын Жекпот
            шагналын болзлыг хоёр болон түүнээс дээш тоглогч хангасан тохиолдолд
            шагналыг тэнцүү хуваарилан олгоно.
            <br />
            Жишээ нь: 5000 төгрөгийн тасалбараар Жекпот шагнал хожсон 2 ялагч
            байх тохиолдолд 1,000,000,000 төгрөгийг тэнцүү хуваарилан ялагч тус
            бүрд 500,000,000 сая төгрөгийг олгоно.
            <br />
            4.4. Жекпот шагналын санд байгаа бэлэн мөнгийг энэхүү Үйлчилгээний
            нөхцөлийн 4.1-д заасан тоо тохирсон бүх ялагчдад худалдан авсан
            тасалбарын үнээс хамааран хувь тэнцүүлэн олгоно.
            <br />
            Жишээ нь: 5000 төгрөгийн тасалбар болон 1,000 төгрөгийн тасалбараар
            жекпот шагнал хожсон 2 ялагч байх тохиолдолд нийт 1,000,000,000
            төгрөгийн шагналыг тасалбарын үнийн дүнгээс хамааран хувь тэнцүүлэн
            олгох буюу 833,333,333 төгрөг, 166,666,667 төгрөг тус бүрээр хуваан
            олгоно.
            <br />
            4.5. 1,000 төгрөгийн тасалбараар 1 тоглогч жекпот шагналын ялагч
            болсон тохиолдолд шагналыг тус бүрд нь 3.3-т заасны дагуу олгох
            бөгөөд хэрэв 2 болон түүнээс дээш ялагч байх тохиолдолд 1,000
            төгрөгийн тасалбарын Жекпот шагналын хэмжээг хоёр дахин үржүүлсэнтэй
            тэнцэхүйц хэмжээний мөнгөн дүнг нийт ялагчдад тэнцүү хуваан олгоно.
            <br />
            4.6. Жекпот шагналыг дараах хоёр хэлбэрээр олгож болох бөгөөд ялагч
            ямар хэлбэрээр хүлээн авахаа шийдвэрлэснээс хойш 60 хоногийн дотор
            олгоно. Үүнд:
            <br />
            4.6.1. Нэг удаагийн мөнгөн төлбөр
            <br />
            4.6.2. Жилийн тэтгэмж /нийт 10 жил/
            <br />
            4.7. Тоглогч нь жекпот шагналын ялагч болсон тохиолдолд тухайн
            тохирлоор ялагчийг тодруулсан хөтлөгч тус бүрд өөрийн хожсон жекпот
            шагналын 1%-тай тэнцэх хэмжээний урамшуулал олгохыг зөвшөөрч байгаа
            болно.
            <br />
            <p className="text-center text-2xl font-medium my-6">
              5. Лотерейн тохирол
            </p>{" "}
            5.1. Лотерейн тохирлыг явуулахдаа Smartplay компанийн үйлдвэрлэсэн
            Megamillions лотерейд ашиглагддаг ижил төрлийн төхөөрөмжийг
            ашиглана. Тохирлын үндсэн 5 /тав/ тоог Criterion II загварын
            төхөөрөмжөөр, азын 1 /нэг/ тоог Messenger загварын төхөөрөмжөөр
            шалгаруулна.
            <br />
            5.2. Лотерейн тохирол бүрд олон улсын байгууллагаас баталгаажсан
            5.1-д заасан төхөөрөмж ашиглан тоглолтын хослол бүхий зургаан /6/
            тоог санамсаргүй байдлаар тогтоох бөгөөд уг тоглолтын хослол нь
            тухайн лотерейн бүх шагналын ялагчийг тодруулахад үндэслэл болно.
            <br />
            5.3. Бүх тохирлыг олон нийтэд нээлттэй, ил тод явуулна.
            <br />
            5.4. Санамсаргүй сонгогдсон 6-н тоо бүхий тоглолтын хослолоор
            ялагчийг шалгаруулж, хонжворыг олгох тул Лотерейн тохирлыг ямар ч
            тохиолдолд хүчингүйд тооцохгүй.
            <br />
            5.5. Лотерейны ялагч тодорсон тохиолдолд Ялагчийн Hipay-д бүртгэлтэй
            утас, эсхүл цахим шуудангаар мэдэгдэх бөгөөд “HiPay” мини апп болон
            Megaball Facebook хуудсаар дамжуулан нийтлэх болно.
            <br />
            5.6. Хонжворыг бусдад шилжүүлэх, солих, бараа материалаар дүйцүүлэн
            олгох боломжгүй болно.
            <br />
            5.7. Компани нь Ялагчийн өмнөөс эд мөнгөний хонжворт сугалааны
            татвар /40%/-г хувь хүний орлогын албан татварт суутган хуульд
            заасны дагуу төсөвт шилжүүлж тайлагнана.
            <br />
            5.8. Энэхүү үйлчилгээний нөхцөлд тусгайлан заагаагүй бол хонжвортой
            холбоотой хувь хүний орлогын албан татвараас бусад бүх зардал,
            хураамжийг Ялагч өөрөө хариуцна.
            <br />
          </div>
          <div className="mb-8">
            <p className="text-center text-2xl font-medium my-6">
              6. Лотерейн хонжвор олгох
            </p>
            6.1. Жекпот болон хоёр, гуравдугаар шагналын ялагч болсон тоглогч нь
            “Мегаболл” ХХК-ийн оффист өөрийн биеэр хүрэлцэн ирж, маягт бөглөн
            баталгаажуулснаар хонжвор хүлээн авах эрх үүснэ.
            <br />
            6.2. Ялагч нь Энэхүү журмын 5.5-т заасан аргаар 6.1-д заасан
            мэдэгдэл хүргүүлснээс хойш Жекпот шагналын хувьд 60 хоног,
            хоёрдугаар шагнал, 5000, 2500 төгрөгийн тасалбараар гуравдугаар
            шагналын хувьд 30 хоногийн дотор баталгаажуулалт хийнэ.
            <br />
            6.3. Журмын 6.2-д заасан хугацаа хэтэрсэн тохиолдолд шагналыг
            хүчингүй болгож, дараагийн тохирлын шагналыг санг энэ журамд заасан
            хувиар нэмнэ.
            <br />
            6.4. Лотерейн ялагчийн хонжворыг зөвхөн Хэрэглэгчийн бүртгэлийн
            мэдээллийг үндэслэн олгоно.
            <br />
            6.5. Хоёр болон гуравдугаар шагналын ялагч болсон тоглогчид шагнал
            хүлээн авахаар баталгаажуулалт хийснээс хойш арван тав /15/ хоногийн
            дотор хонжворыг олгоно.
            <br />
            6.6. Жекпот болон энэхүү үйлчилгээний нөхцөлийн 6.2-т зааснаас бусад
            бүх шагналыг тохирол явагдсанаас нэг /1/ хоногийн дотор олгоно.
            <br />
            6.7. Лотерейн хонжвор баталгаажуулах, төлбөр төлөхтэй холбоотой
            Компанийн шийдвэр эцсийнх байх бөгөөд бүх Тоглогчид заавал
            биелүүлнэ.
            <br />
            6.8. Лотерейн бүх хонжворыг Компани хариуцна.
            <br />
            <p className="text-center text-2xl font-medium my-6">
              7. Мэдээллийн нууцлал{" "}
            </p>
            7.1. Компани нь Хэрэглэгчийн овог нэр, имэйл хаяг, утасны дугаар,
            төрсөн он сар өдөр, хүйс, hipay хэтэвчийн дансны дугаар, Лотерейд
            оролцож буй мэдээлэл, тохирлоос хожсон шагнал, тоо зэрэг хувийн
            мэдээллийг өөрсдийн мэдээллийн санд хадгална.
            <br />
            7.2. Хэрэглэгч нь өөрийн хувийн мэдээллийг компанийн маркетингийн
            үйл ажиллагаа, лотерейны зохион байгуулалтад сайжруулалт хийх, шинэ
            үйлчилгээг санал болгох зорилгын хүрээнд хуульд заасан үндэслэл,
            журмын дагуу ашиглуулахыг зөвшөөрч байгаа болно.
            <br />
            7.3. Компани нь хэрэгчдийн мэдээллийг ашиглах бөгөөд олон нийтэд
            зөвшөөрөлгүйгээр эсхүл хуульд заасан эрх бүхий этгээдээс бусад
            этгээдэд задруулахгүй.
            <br />
            7.4. Хэрэглэгч нь хууль тогтоомжид зааснаар тусгайлан тогтоосон
            хугацаагаар хадгалахаас бусад энэхүү Үйлчилгээний нөхцөлийн 7-д
            заасан өөрт хамаарах мэдээллийг мэдээллийн сангаас устгах хүсэлтийг
            Компанид гаргаж болно.
            <br />
            7.5. Компани нь энэхүү үйлчилгээний нөхцөлийн 7.4-д заасан хүсэлтийг
            хүлээн авснаас хойш ажлын 10 өдрийн дотор мэдээллийг устган
            Хэрэглэгчид мэдэгдэнэ.
            <br />
            7.6. Компани нь хүний хувийн мэдээллийг олон нийтэд ил болгохгүй
            бөгөөд цуглуулах, ашиглах, боловсруулах явцад санаатай болон
            санамсаргүйгээр алдах, устгах, өөрчлөх, хуулбарлах, бусад хууль бус
            ажиллагаанаас хамгаална.
            <br />
            <p className="text-center text-2xl font-medium my-6">
              {" "}
              8. Бусад нөхцөл{" "}
            </p>
            8.1. Компани болон “Хай Пэймэнт Солюшнс” ХХК нь хонжвороос үүдсэн
            тоглогчдын санхүүгийн эрсдэлийг хариуцахгүй болно.
            <br />
            8.2. Тоглогч нь Үйлчилгээний нөхцөлийг анхааралтай уншиж танилцах
            үүргээ зохих ёсоор гүйцэтгээгүй нь Үйлчилгээний нөхцөл, түүний
            аливаа заалтыг танилцаагүй, мэдээгүй, ойлгоогүй, хүлээн зөвшөөрөөгүй
            гэж үзэх, эсхүл Тоглогчийг аливаа үүрэг, хариуцлагаас чөлөөлөх
            үндэслэл болохгүй.
            <br />
            8.3. Тоглогч нь нэвтрэх нэр, нууц үг, баталгаажуулах кодын нууцлалыг
            хадгалах үүрэгтэй бөгөөд холбогдох мэдээллийг гуравдагч этгээдэд
            дамжуулсан, алдсан, мэдээллийн аюулгүй байдлыг хамгаалж чадаагүйгээс
            үүдэх хохирлыг бүрэн хариуцна.
            <br />
            8.4. Компани нь энэхүү Үйлчилгээний нөхцөлд өөрчлөлт оруулах,
            шинэчлэх тохиолдолд хэрэглэгчид 7 хоногийн өмнө мэдэгдэнэ.
            <br />
            8.5. Компани нь Лотерейтэй холбогдуулан бусад дүрэм, журам,
            нөхцөлүүдийг баталж болох ба тэдгээрийг цахим хуудаст байршуулснаар
            энэхүү Үйлчилгээний нөхцөлийн салшгүй хэсэг болно.
            <br />
            8.6. Энэхүү Үйлчилгээний нөхцөлийг Монгол Улсын хуулийн дагуу
            зохицуулах ба талуудын хооронд маргаан гарвал Монгол Улсын шүүхээр,
            Монгол хэлээр шийдвэрлүүлнэ.
            <br />
          </div>
        </div>
        <img
          alt=""
          src={contract}
          className="absolute left-0 pointer-events-none z-0 md:hidden"
        />
      </div>
    </div>
  );
};
export default Contract;
