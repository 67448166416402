import MainLayout from "components/MainLayout";
import About from "screens/About";
import Awards from "screens/Awards";
import Call from "screens/CallCenter/CallCenter";
import ComingSoon from "screens/ComingSoon";
import Contract from "screens/Contract";
import FAQ from "screens/FAQ";
import Home from "screens/Home";
import PotHistory from "screens/PotHistory/PotHistory";
import Print from "screens/Print";
import RNG from "screens/RNG";

export const MainRoutes = [
  {
    component: MainLayout,
    routes: [
      {
        component: Home,
        path: "/",
        exact: true,
      },
      {
        component: About,
        path: "/about",
        exact: true,
      },
      {
        component: FAQ,
        path: "/faq",
        exact: true,
      },
      {
        component: Call,
        path: "/call",
        exact: true,
      },
      {
        component: Contract,
        path: "/contract",
        exact: true,
      },
      {
        component: Awards,
        path: "/awards",
        exact: true,
      },
      {
        component: RNG,
        path: "/rng",
        exact: true,
      },
      {
        component: PotHistory,
        path: "/history",
        exact: true,
      },
      {
        component: Print,
        path: "/print",
        exact: true,
      },
    ],
  },
];
