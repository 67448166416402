import { ButtonBase } from "@mui/material";
import rng from "assets/Frame 427320543.png";
import example from "assets/Frame 427320584.png";
import Icon from "components/Icon";
import { colors } from "constants/colors";
import React, { useState } from "react";
import {Helmet} from "react-helmet";

const RNG = () => {
  const [num, setNum] = useState([15, 15, 15, 15, 15, 5]);

  const randomNumber = (max) => {
    return Math.floor(Math.random() * max) + 1;
  };

  const handleClick = () => {
    // setNum(randomNumberInRange(1, 5));
    let arr = [];
    for (let i = 0; i < 5; i++) {
      let num = randomNumber(45);
      while (arr.includes(num)) {
        num = randomNumber(45);
      }
      arr.push(num);
    }
    let mega_ball = randomNumber(5);
    setNum([...arr, mega_ball]);
  };
  return (
    <div className="mt-[93px] z-0 bg-black10">
      <Helmet>
        <title>Megaball | Random Number Generator</title>
      </Helmet>
      <img alt="" src={rng} className="w-full" />
      <div className="flex justify-center bg-black10 flex-col items-center">
        <div className="bg-black0 drop-shadow-2xl rounded-[36px] max-w-[785px] flex h-40 mt-[-2%] mb-8 flex-col items-center ">
          <div className="bg-black0 text-[24px] drop-shadow-2xl rounded-[36px] text-[#183C72] flex gap-4 p-3 ">
            <div
              className="rounded-full h-[58px] aspect-1 flex justify-center md:h-[36px] sm:h-[28px] items-center font-bold"
              style={{
                background:
                  "radial-gradient(63.67% 61.25% at 36.25% 21.25%, #FFFFFF 0%, #DBE3EF 100%)",
              }}
            >
              {num[0]}
            </div>
            <div
              className="rounded-full h-[58px] aspect-1 flex justify-center md:h-[36px] sm:h-[28px] items-center font-bold"
              style={{
                background:
                  "radial-gradient(63.67% 61.25% at 36.25% 21.25%, #FFFFFF 0%, #DBE3EF 100%)",
              }}
            >
              {num[1]}
            </div>
            <div
              className="rounded-full h-[58px] aspect-1 flex justify-center md:h-[36px] sm:h-[28px] items-center font-bold"
              style={{
                background:
                  "radial-gradient(63.67% 61.25% at 36.25% 21.25%, #FFFFFF 0%, #DBE3EF 100%)",
              }}
            >
              {num[2]}
            </div>
            <div
              style={{
                background:
                  "radial-gradient(63.67% 61.25% at 36.25% 21.25%, #FFFFFF 0%, #DBE3EF 100%)",
              }}
              className="rounded-full h-[58px] aspect-1 flex justify-center md:h-[36px] sm:h-[28px] items-center font-bold"
            >
              {num[3]}
            </div>
            <div
              style={{
                background:
                  "radial-gradient(63.67% 61.25% at 36.25% 21.25%, #FFFFFF 0%, #DBE3EF 100%)",
              }}
              className="rounded-full h-[58px] aspect-1 flex justify-center md:h-[36px] sm:h-[28px] items-center font-bold"
            >
              {num[4]}
            </div>
            <div className="rounded-full h-[58px] aspect-1 flex justify-center md:h-[36px] sm:[28px] items-center bg-mega-ball text-black0 font-bold">
              {num[5]}
            </div>
          </div>
          <ButtonBase
            style={{
              padding: 8,
              paddingTop: 12,
              paddingBottom: 12,
              borderRadius: 12,
              backgroundColor: "#FF5D35",
              color: "white",
              marginTop: 16,
            }}
            onClick={handleClick}
          >
            <Icon icon="ic20-arrow-right" color={colors.black0} />
            <p className="font-bold">Санамсаргүйгээр тоо сонгох</p>
          </ButtonBase>
        </div>
        <p className="text-center text-black70 max-w-[700px] px-4">
          Нэг удаагийн лотерейн тоглолт буюу тасалбар нь тухайн хэрэглэгчийн
          өөрөө сонгосон <br className="hidden md:block" /> эсвэлсистемээс
          автомат санал болгосон дараах бүтэц бүхий дугаарлалттай байна.
        </p>
        <p className="text-center font-bold text-black70 mt-5 px-4">
          Үндсэн дугаар 1 – 45 хүртэлх давхардахгүй 5 дугаар
        </p>
        <p className="text-center font-bold text-black70 mt-1 px-4">
          Азын дугаар: 1 – 5 хүртэлх давхардахгүй 1 дугаар
        </p>
        <div className="flex justify-center">
          <img alt="" src={example} className="h-full w-full" />
        </div>
      </div>
    </div>
  );
};

export default RNG;
