const sharedConstants = {};

const testConstants = {
  apiBaseUrl: "https://api-test.loto.mn:8080/api/",
};

const prodConstants = {
  apiBaseUrl: "https://api.loto.mn/api/"
}

export const config = {
  ...sharedConstants,
  // ...testConstants,
  ...prodConstants,
};
