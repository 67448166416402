import { apiClient } from "api/apiClient";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { adminApis, queryKeys } from "./index";
const getMatchInfo = async (id) =>
  (await apiClient.get(`web/match-info?id=${id}`))?.data;

export const useGetMatchInfo = (id) => {
  const queryClient = useQueryClient();
  return useMutation((data) => getMatchInfo(data), {
    onSuccess: () => {},
  });
};
