import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { adminApis } from "api";
import { setToken } from "api/apiClient";

const startPending = (state) => {
  state.loading = true;
};
const stopPending = (state) => {
  state.loading = false;
};

const initialState = {
  loading: false,
  menuOpen: false,
  user: null,
};

export const login = createAsyncThunk(
  "main/login",
  async (data, { getState }) => {
    if (getState()?.main?.loading) {
      return await adminApis.login(data);
    }
  }
);

export const logout = createAsyncThunk(
  "main/logout",
  async (_, { getState }) => {
    if (getState()?.main?.loading) {
      return await adminApis.logout();
    }
  }
);

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    showMenu: (s) => {
      s.menuOpen = true;
    },
    hideMenu: (s) => {
      s.menuOpen = false;
    },
  },
  extraReducers: {
    [login.pending]: startPending,
    [login.fulfilled]: (state, action) => {
      if (action?.payload?.code === 1 && action?.payload?.token) {
        state.user = action?.payload?.token;
        setToken(action?.payload?.token);
      }
      state.loading = false;
    },
    [login.rejected]: stopPending,
    [logout.pending]: startPending,
    [logout.fulfilled]: (state, action) => {
      if (action?.payload?.code === 1) {
        state.user = null;
      }
      state.loading = false;
    },
    [logout.rejected]: stopPending,
  },
});

export default mainSlice.reducer;
export const { hideMenu, showMenu } = mainSlice.actions;

const getMain = createSelector([(state) => state.main], (main) => main);

export const mainSelectors = {
  getMain,
};
