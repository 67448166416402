import { useGetMatchInfo } from "api/useGetMatchInfo";
import { useGetPotHistory } from "api/useGetPotHistory";
import contract from "assets/contract.svg";
import lines from "assets/lines.svg";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import PotBalls from "screens/PotHistory/PotBalls";

const PotHistory = () => {
  const { data } = useGetPotHistory() || {};
  const [prize_info, setPrizeInfo] = useState({});
  const { mutateAsync, isLoading } = useGetMatchInfo();
  // const {
  //   data: matchInfo,
  //   isLoading: curLoading,
  //   isFetching: curFetching,
  // } = useGetMatchInfo(prize_info?.id) || {};
  const getPrizeInfo = async (id) => {
    const res = await mutateAsync(id);
    setPrizeInfo((p) => {
      return { ...p, [id]: res?.data };
    });
  };
  return (
    <div className="mt-[93px] z-0 bg-black10">
      <Helmet>
        <title>Megaball | Тохирлын түүx</title>
      </Helmet>
      <div>
        <div className="flex bg-[#0048A6] justify-center relative  z-10 overflow-hidden h-[100px] items-center ">
          <p className="font-bold text-4xl text-black0 text-center md:text-[32px]">
            Тохирлын түүх
          </p>
          <img
            alt=""
            src={lines}
            className=" object-cover absolute right-0 bottom-0 pointer-events-none z-0 "
          />
        </div>
        <div className="py-16 relative flex justify-center  z-10 ">
          <div className="flex drop-shadow-xl bg-black0 rounded-xl p-6">
            <div className="text-black55 ">
              <div className="flex justify-between md:hidden">
                <p>Огноо</p>
                <p>Тохирол</p>
                <p>Нийт хожлын дүн</p>
              </div>
              {data?.length > 0 &&
                data?.map((item, index) => (
                  <PotBalls
                    key={index}
                    item={item}
                    length={data?.length}
                    isMegaBall={index === 5}
                    prize_info={prize_info[String(item?.id)]}
                    getPrizeInfo={getPrizeInfo}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>

      <img
        alt=""
        src={contract}
        className="absolute pointer-events-none z-0 left-0 top-0 pointer-events-none md:hidden"
      />
    </div>
  );
};
export default PotHistory;
