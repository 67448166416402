import { ButtonBase } from "@mui/material";
import megaballMini from "assets/megabalMini.svg";
import sidemenu from "assets/sidemenu.svg";
import HeaderButton from "components/ButtonBase/ButtonBase";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { showMenu } from "#redux/slices/mainSlice";

function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

const Header = () => {
  const dispatch = useDispatch();
  const handleMenu = () => {
    dispatch(showMenu());
  };

  const history = useHistory();
  return (
    <div className=" bg-[#0048A6]  flex w-full items-center  fixed z-10 justify-between  h-[93px] md:justify-center">
      <div className="flex ml-16 md:ml-0  md:justify-center ">
        <ButtonBase
          sx={{
            borderRadius: 24,
            alignItems: "center",
            padding: "10px",
          }}
          onClick={() => {
            history.push("/");
            topFunction();
          }}
        >
          <img
            alt=""
            src={megaballMini}
            className="pointer-events-none flex  h-[40px] md:h-[30px] "
          />
        </ButtonBase>
      </div>
      <div className="hidden md:flex absolute right-0 top-0 bottom-1.5 right-3">
        <ButtonBase sx={{ borderRadius: 24 }} onClick={handleMenu}>
          <img alt="" src={sidemenu} />
        </ButtonBase>
      </div>

      <div className="flex mr-16 md:hidden">
        <HeaderButton link={"/"} title={"Нүүр"} />
        <HeaderButton link={"/about"} title={"Бидний тухай"} />
        <HeaderButton link={"/history"} title={"Тохирлын түүх"} />
        <HeaderButton link={"/awards"} title={"Шагналын сан"} />
        <HeaderButton link={"/faq"} title={"FAQ"} />
        <HeaderButton link={"/rng"} title={"RNG"} />
      </div>
    </div>
  );
};

export default Header;
