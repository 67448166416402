import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import question from "assets/question.svg";
import { colors } from "constants/colors";
import React from "react";

const CommonQ = () => {
  const Accordion = styled((props) => {
    return <MuiAccordion disableGutters elevation={0} square {...props} />;
  })(({ theme }) => ({
    marginBottom: 24,
    borderRadius: 24,
    backgroundColor: "#F5F5F5",
    "&:before": {
      display: "none",
    },
  }));
  return (
    <div className="flex justify-center w-full px-8 py-[120px] md:py-[60px]">
      <div className="max-w-[1268px] flex items-center flex-col relative">
        <p className="text-amber-600 text-[40px] font-bold flex text-center justify-center mb-8 md:text-2xl">
          Түгээмэл асуулт <br className="hidden md:block" />
          хариулт
        </p>

        <p className="text-center mb-[68px] max-w-[650px] md:mb-[56px]">
          Хэрвээ таны хайсан асуулт энд байхгүй бол манай харилцагчийн
          үйлчилгээний 7755 2211 дугаарт хандан лавлана уу. Таны асуултад
          хариулахдаа бид үргэлж таатай байх болно
        </p>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="warning" fontSize="large" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <p className="text-lg text-black70 my-4 ml-8 text-start font-bold">
              Megaball гэж юу вэ?
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                borderTop: 1,
                borderColor: "#FF5D35",
                opacity: 0.2,
                marginX: 4,
              }}
            />
            <Typography
              sx={{
                fontSize: 14,
                marginX: 4,
                paddingTop: 4,
                color: colors.black55,
              }}
            >
              “Мegaball” нь америк стандартыг бүрэн хангасан Монголын анхны
              лотерей юм. Тодруулбал тус лотерейн тохирлын машинууд нь дэлхийн
              130 гаруй улс оронтой хамтран ажилладаг АНУ-ын “Smartplay”
              компанид үйлдвэрлэсэн. Мegaball-н ашиглаж буй Criterion II,
              Messenger нэртэй тохирлын машин нь АНУ-ын алдарт Мegamillions
              лотерейд ашиглагддаг. Мөн “Megaball” нь нийгэмд тустай Монголын
              анхны лотерей юм. Бид жилийн үйл ажиллагааны орлогоосоо 2 тэрбум
              төгрөгийг хүүхдийн эрүүл, мэнд боловсролд хандивлахаар төлөвлөсөн.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="warning" fontSize="large" />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <p className="text-lg text-black70 my-4 ml-8 text-start font-bold">
              Тохиролыг хаанаас үзэх вэ?
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                borderTop: 1,
                borderColor: "#FF5D35",
                opacity: 0.2,
                marginX: 4,
              }}
            />
            <Typography
              sx={{
                fontSize: 14,
                marginX: 4,
                paddingTop: 4,
                color: colors.black55,
              }}
            >
              “Megaball” лотерейн тохирлыг долоо хоног бүрийн Лхагва, Ням
              гарагийн 20 цаг 30 минутаас UBS, TV9 телевизээр орон даяар шууд
              дамжуулдаг. Мөн манай “Megaball” фэйсбүүк пэйжээр хүлээн авч үзэх
              боломжтой.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="warning" fontSize="large" />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <p className="text-lg text-black70 my-4 ml-8 text-start font-bold">
              Тасалбарын үнэ яагаад хоёр сонголттой байгаа вэ?
            </p>
          </AccordionSummary>
          <AccordionDetails style={{ marginBottom: 12 }}>
            <Typography
              sx={{
                borderTop: 1,
                borderColor: "#FF5D35",
                opacity: 0.2,
                marginX: 4,
              }}
            />
            <Typography
              sx={{
                fontSize: 14,
                marginX: 4,
                paddingTop: 4,
                color: colors.black55,
              }}
            >
              Хонжворын үнийн дүнгээс шалтгаалан тасалбарын үнэ өөр өөр байх
              бөгөөд Та өөрийн тоглох тоогоо сонгон аль хонжворт азаа үзэхээ
              шийдэх боломжийг бид олгож байна. 5,000 төгрөгийн тасалбар
              авсанаар Та супер шагнал болох тэрбум төгрөг хожих боломжтой бол
              1,000 төгрөгийн тасалбар авсанаар 200,000,000 төгрөх хожих
              боломжтой юм.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <img
          alt=""
          src={question}
          className="h-[104px] absolute top-0 right-0 md:h-[43px]"
        />
      </div>
    </div>
  );
};
export default CommonQ;
