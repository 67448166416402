import megaball from "assets/megaballblack.png";
import qr from "assets/QR_code_for_mobile_English_Wikipedia.svg.webp";
import CurrentTickets from "components/CurrentTickets";
import { Line } from "components/Organs/Organs";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";

const Print = () => {
  let componentRef = useRef();
  return (
    <div className="flex h-screen justify-center mt-40">
      <p>dasdas</p>
      <ReactToPrint
        trigger={() => {
          // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
          // to the root node of the returned component as it will be overwritten.
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          return <a href="#">Print this out!</a>;
        }}
        content={() => componentRef}
      />
      <ComponentToPrint ref={(el) => (componentRef = el)} />
    </div>
  );
};

export default Print;

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const dummy = [1, 2, 3, 4, 5, 6];
  return (
    <div ref={ref} className="flex h-screen mt-[150px] justify-center ">
      <div className="flex flex-col items-center w-full  h-fit">
        <p>Тохирол:2023/05/23 20:30</p>
        <div className="flex flex-col items-center w-fit mt-4 gap-2">
          <Line />
          <img src={megaball} alt="" className="h-16" />
          <p>Таны утасны дугаарыг: 99889988</p>
          <p>Таны тасалбар</p>
          <Line />
          {dummy?.map((item, index) => (
            <div key={index}>
              <CurrentTickets item={item} />
            </div>
          ))}
          <div className="flex justify-between w-full">
            <p>Нийт тасалбар</p>
            <p>3 ш</p>
          </div>
          <div className="flex justify-between w-full">
            <p className="text-[20px] font-bold ">Нийт үнэ</p>
            <p className="text-[20px] font-bold "> 7,000 ₮</p>
          </div>
          <Line />
          <p>Танд аз хүсье.</p>
          <p className="w-[300px] text-[14px] text-center ">
            Энэхүү лотерейг зөвхөн 18 наснаас дээш насны Монгол улсын иргэн
            тоглох боломжтой бөгөөд БИ үүнийг баталж байна гэдэг ч юмуу
          </p>
          <img src={qr} alt="" className="w-52" />
        </div>
      </div>
    </div>
  );
});
