import { useMediaQuery } from "@mui/material";
import megaball from "assets/megaball.svg";
import React from "react";

const BallItem = ({ ball, isMegaBall, size = 58 }) => {
  const isPhone = useMediaQuery("(max-width: 768px)");
  return (
    <div
      className={
        isMegaBall
          ? "rounded-full aspect-1 flex justify-center items-center"
          : "rounded-full aspect-1 flex justify-center items-center"
      }
      style={{
        height: size,
        background:
          "radial-gradient(63.67% 61.25% at 36.25% 21.25%, #FFFFFF 0%, #DBE3EF 100%)",
      }}
    >
      {isMegaBall && (
        <img
          alt=""
          src={megaball}
          className="absolute z-0"
          style={{ height: size }}
        />
      )}
      <p
        className={
          isMegaBall
            ? "text-black0 font-extrabold absolute"
            : "font-extrabold absolute"
        }
        style={{fontSize: isPhone ? 14 : 24}}
      >
        {ball}
      </p>
    </div>
  );
};

export default BallItem;
