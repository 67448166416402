import { ButtonBase } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

const HeaderButton = (props) => {
  const { title, link } = props;
  const history = useHistory();
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <ButtonBase
      sx={{ borderRadius: 24, padding: "10px" }}
      onClick={() => {
        history.push(String(link));
        topFunction();
      }}
    >
      <p className="text-xl text-amber-50 ">{title}</p>
    </ButtonBase>
  );
};

export default HeaderButton;
