import {
  applyMiddleware,
  combineReducers,
  createStore,
} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import thunk from "redux-thunk";

import mainSlice from "./slices/mainSlice";

const encryptor = encryptTransform({
  secretKey: "so_far_so_good",
  onError: function (error) {
    console.log("encrypting error>>>>>>");
  },
});

const rootPersistConfig = {
  // Root?
  key: "root",
  // Storage Method (React Native)
  storage,
  // Whitelist (Save Specific Reducers)
  whitelist: ["main"],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [],
  transforms: [encryptor],
};

const rootReducer = combineReducers({
  main: mainSlice,
});

// Middleware: Redux Persist Reducer
const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

// Redux: Store
const store = createStore(persistedReducer, applyMiddleware(thunk));

// Middleware: Redux Persist Persister
let persistor = persistStore(store);

// Exports
export { persistor, store };
