import facebook from "assets/facebook.svg";
import instagram from "assets/instagram.svg";
import twitter from "assets/twitter.svg";
import youtube from "assets/youtube.svg";
import React from "react";
import { Link } from "react-router-dom";
const Social = (show) => {
  return (
    <div className="mt-6 md:hidden">
      <Link
        to={"/"}
        onClick={(event) => {
          event.preventDefault();
          window.open(
            "https://www.instagram.com/megaballmongolia/?fbclid=IwAR1hbRfxJx2g2pU1onno4noy9EdiboKS4ATdHAjLQGDUblcdhJPbiHulkFo"
          );
        }}
      >
        <img alt="" src={instagram} className="h-[24px] mr-8" />
      </Link>
      <Link
        to={"/"}
        onClick={(event) => {
          event.preventDefault();
          window.open("https://www.youtube.com/@megaballmongolia");
        }}
      >
        <img alt="" src={youtube} className="h-[26px] mr-8" />
      </Link>
      <Link
        to={"/"}
        onClick={(event) => {
          event.preventDefault();
          window.open(
            "https://www.facebook.com/Megaballmongolia?mibextid=LQQJ4d"
          );
        }}
      >
        <img alt="" src={facebook} className="h-[24px] mr-8" />
      </Link>
      <Link
        to={"/"}
        onClick={(event) => {
          event.preventDefault();
          window.open(
            "https://twitter.com/megaballmongol?fbclid=IwAR2M6fzvA1fhSAyHdDGuZHZlxVB0AaTPjIMmm4uBOOPU3GqxXtf--pdtWc0"
          );
        }}
      >
        <img alt="" src={twitter} className="h-[24px]" />
      </Link>
    </div>
  );
};
export default Social;
