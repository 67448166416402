import { ButtonBase } from "@mui/material";
import { useGetDataPot } from "api/useGetDataPot";
import tv9 from "assets/guides/tv9.svg";
import Jackpot from "assets/Jackpot.svg";
import lines from "assets/lines.svg";
import megaballMini from "assets/megabalMini.svg";
import ubs from "assets/ubs.svg";
import MiniMenu from "components/MiniMenu/MiniMenu";
import Social from "components/Social/Social";
import Social2 from "components/Social/Social2";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useCountdown } from "utils";

function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}
const thisYear = new Date().getFullYear();
const Countdown = ({ s }) => {
  const [days, hours, minutes, seconds] = useCountdown(s);

  return (
    <div className="">
      <p
        className={
          "z-0  text-[40px] md:text-[25px] text-[#FFF802] font-bold font-sans md:pt-4"
        }
      >
        {days} : {hours} : {minutes} : {seconds}
      </p>
    </div>
  );
};
const Footer = () => {
  const { data } = useGetDataPot();
  const { diff_date, amount, section_number } = data || {};
  const s = useMemo(() => Math.floor(+diff_date || 0), [diff_date]);
  const history = useHistory();
  return (
    <div className="flex w-full justify-center bg-blue">
      <div className="bg-blue  relative overflow-hidden  w-full">
        <div className="relative overflow-hidden justify-center   mx-8">
          <div className=" pt-[50px] grid-rows-2  z-0 h-fit ">
            <div className="flex mb-10 justify-between md:flex-col">
              <div>
                <img
                  alt=""
                  src={megaballMini}
                  className="h-[80px] md:h-[50px]"
                />
                <Social />
              </div>
              <MiniMenu />
            </div>
            <div className=" border-x-0 border-solid border-y-[1px] border-[#4064AC]">
              <div className="flex justify-between py-8 md:flex-col md:justify-center md:items-center">
                <img
                  alt=""
                  src={ubs}
                  className="md:w-[250px] items-center flex justify-center md:hidden"
                />
                <img alt="" src={Jackpot} className="hidden md:block" />
                <div className="flex flex-col text-center items-center">
                  <p className="text-[#FFF802] font-bold text-2xl md:mt-3">
                    Тохирол {section_number} эхлэхэд
                  </p>
                  <Countdown s={s} />
                  <div className="hidden md:block md:flex flex-row gap-8 ">
                    <img alt="" src={ubs} className=" h-[40px]" />
                    <img alt="" src={tv9} className=" h-[40px]" />
                  </div>
                </div>
                <img alt="" src={tv9} className="md:hidden" />
              </div>
            </div>
            <div className="grid grid-cols-2 py-12 pb-20  md:grid-cols-1 ">
              <p className="text-black0 flex  md:mt-5 md:hidden">
                Copyright © {thisYear}. Megaball LLC
              </p>
              <div className="flex w-full md:flex-col items-center ">
                <ButtonBase
                  sx={{ borderRadius: 24 }}
                  onClick={() => {
                    history.push("/contract");
                    topFunction();
                  }}
                >
                  <p className="text-black0 ">Үйлчилгээний нөхцөл</p>
                </ButtonBase>
                <ButtonBase
                  sx={{ borderRadius: 24, marginLeft: 16 }}
                  onClick={() => {
                    history.push("/call");
                    topFunction();
                  }}
                >
                  <p className="text-black0 hidden ">Нууцлалын бодлого</p>
                </ButtonBase>

                <ButtonBase
                  sx={{ borderRadius: 24 }}
                  onClick={() => {
                    history.push("/call");
                    topFunction();
                  }}
                >
                  <p className="text-black0 col-end-5 md:col-end-2 md:py-2">
                    Холбоо барих
                  </p>
                </ButtonBase>
                <Social2 show={1} />
                <p className="text-black0 flex  md:mt-5 hidden md:block">
                  Copyright © {thisYear}. Megaball LLC
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          alt=""
          src={lines}
          className=" absolute right-0  bottom-0 pointer-events-none "
        />
      </div>
    </div>
  );
};

export default Footer;
