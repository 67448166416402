import React from "react";

const CurrentTickets = ({ item }) => {
  return (
    <div className=" flex flex-col items-center  ">
      <div className="flex  gap-3    items-center">
        <div className=" border-dotted border-y-0 border-l-0 border-[1px] p-1">
          <p className="text-[15px]">{item}.</p>
        </div>
        <div className="flex gap-3 font-bold items-center">
          <p className="text-[15px]">25</p>
          <p className="text-[15px]">12</p>
          <p className="text-[15px]">32</p>
          <p className="text-[15px]">15</p>
          <p className="text-[15px]">33</p>
          <div className="flex items-center justify-center border-dotted border-y-0 border-l-0 border-[1px] pr-2">
            <div className="relative border-solid rounded-full border-[1px] p-1">
              <p className=" ">33</p>
            </div>
          </div>
        </div>
        <div className=" p-1 ">
          <p className="text-sm  font-semibold">1,000₮</p>
        </div>
      </div>
    </div>
  );
};
export default CurrentTickets;
