export const colors = {
  primary: "#0048A6",
  hipay: "#ff3b63",
  black0: "#ffffff",
  black10: "#f5f7fa",
  black25: "#e5e7eb",
  black40: "#9CA5B0",
  black70: "#414C58",
  black55: "#646E7B",
  black85: "#353F4A",
  ballGradient:
    "radial-gradient(63.67% 61.25% at 36.25% 21.25%, #FFFFFF 0%, #DBE3EF 100%)",
  yellow: "#FFB300",
  orange: "#FF5D35",
  orange50: "#FF5D3580",
  red: "#F7003A",
  blue: "#003399",
  grey1: "#EDF3FF",
};
