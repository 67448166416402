import { ButtonBase } from "@mui/material";
import contact from "assets/lines.svg";
import megaballMini from "assets/megabalMini.svg";
import Icon from "components/Icon";
import { colors } from "constants/colors";
import React from "react";
import { slide as Menu } from "react-burger-menu";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { hideMenu, mainSelectors, showMenu } from "#redux/slices/mainSlice";
const SideMenu = () => {
  const { menuOpen } = useSelector(mainSelectors.getMain);
  const dispatch = useDispatch();
  const history = useHistory();
  const onClose = () => dispatch(hideMenu());
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  return (
    <Menu
      right
      isOpen={menuOpen}
      onOpen={() => dispatch(showMenu())}
      onClose={() => dispatch(hideMenu())}
      styles={menuStyles}
    >
      <div className="flex flex-col relative items-start ml-3 overflow-hidden ">
        <div className="mt-3 mb-5 ml-2 flex items-start ">
          <img alt="" src={megaballMini} className="h-10 md:h-7 " />
          <div className="absolute right-0 ">
            <ButtonBase onClick={onClose}>
              <Icon
                icon="ic20-close"
                size={30}
                color={colors.black0}
                className="mr-2"
              />
            </ButtonBase>
          </div>
        </div>
        <ButtonBase
          sx={{ borderRadius: 24, padding: "10px", zIndex: 10 }}
          onClick={() => {
            history.push("/");
            onClose();
            topFunction();
          }}
        >
          <p className="text-xl  text-amber-50">Нүүр</p>
        </ButtonBase>
        <ButtonBase
          sx={{ borderRadius: 24, padding: "10px", zIndex: 10 }}
          onClick={() => {
            history.push("/about");
            onClose();
            topFunction();
          }}
        >
          <p className="text-xl text-amber-50">Бидний тухай</p>
        </ButtonBase>

        <ButtonBase
          sx={{ borderRadius: 24, padding: "10px", zIndex: 10 }}
          onClick={() => {
            history.push("/history");
            onClose();
            topFunction();
          }}
        >
          <p className="text-xl text-amber-50 ">Тохирлын түүх</p>
        </ButtonBase>
        <ButtonBase
          sx={{ borderRadius: 24, padding: "10px", zIndex: 10 }}
          onClick={() => {
            history.push("/faq");
            onClose();
            topFunction();
          }}
        >
          <p className="text-xl text-amber-50">FAQ</p>
        </ButtonBase>
        <ButtonBase
          sx={{ borderRadius: 24, padding: "10px", zIndex: 10 }}
          onClick={() => {
            history.push("/awards");
            onClose();
            topFunction();
          }}
        >
          <p className="text-xl text-amber-50">Шагналын сан</p>
        </ButtonBase>
        <ButtonBase
          sx={{ borderRadius: 24, padding: "10px", zIndex: 10 }}
          onClick={() => {
            history.push("/rng");
            onClose();
            topFunction();
          }}
        >
          <p className="text-xl text-amber-50">RNG</p>
        </ButtonBase>
      </div>
      <img
        alt=""
        src={contact}
        className="absolute h-[300px] bottom-0 right-0 z-0"
      />
    </Menu>
  );
};

const menuStyles = {
  bmBurgerButton: {
    display: "none",
  },
  bmCrossButton: {
    display: "none",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
    maxWidth: "80%",
    top: 0,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    overflow: "hidden",
  },
  bmMenu: {
    background: colors.blue,
    zIndex: 100,
    outline: "none",
    marginTop: 0,
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    // display: "flex",
  },
  bmItem: {
    display: "flex",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
    backdropFilter: "blur(5px)",
    position: "fixed",
    left: 0,
    top: 0,
  },
};

export default SideMenu;
