import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useMediaQuery } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import outline from "assets/outline.svg";
import Ballinfo from "components/BallInfo/Ballinfo";
import React from "react";
import PrizeInfo from "screens/PotHistory/PotWinners";
import { numberWithCommas } from "utils";

const DATA = [
  { prize_name: "PRIZE_JACKPOT", balls: { normal: 5, mega: 1 } },
  { prize_name: "PRIZE_SECOND", balls: { normal: 5, mega: 0 } },
  { prize_name: "PRIZE_THIRD", balls: { normal: 4, mega: 1 } },
  { prize_name: "PRIZE_FOURTH", balls: { normal: 4, mega: 0 } },
  { prize_name: "PRIZE_FIFTH", balls: { normal: 3, mega: 1 } },
  { prize_name: "PRIZE_SIXTH", balls: { normal: 3, mega: 0 } },
  { prize_name: "PRIZE_SEVENTH", balls: { normal: 2, mega: 1 } },
  { prize_name: "PRIZE_EIGHTH", balls: { normal: 1, mega: 1 } },
  {
    prize_name: "PRIZE_FREE_TICKET",
    balls: { normal: 0, mega: 1 },
    alt_name: "PRIZE_NINTH",
  },
];

const PotBalls = ({ item, length, prize_info, getPrizeInfo }) => {
  const { post_date, lottery_numbers, total_won_amount } = item || {};
  const numArr = lottery_numbers?.split(",");
  const date = new Date(post_date);
  const isPhone = useMediaQuery("(max-width: 768px)");
  const Accordion = styled((props) => {
    return <MuiAccordion disableGutters elevation={0} square {...props} />;
  })(({ theme }) => ({
    backgroundColor: "white",
    "&:before": {
      display: "none",
    },
  }));
  const date_format =
    date.getFullYear() +
    " оны " +
    (date.getMonth() + 1) +
    " сарын " +
    date.getDate();
  const onPress = () => {
    if (!prize_info) {
      getPrizeInfo(item?.id);
    }
  };
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={
          !isPhone ? (
            <ExpandMoreIcon
              fontSize="large"
              color="warning"
              sx={{ marginLeft: 2 }}
            />
          ) : (
            <img alt="" src={outline} className="h-8" />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={!isPhone ? { flexDirection: "row" } : { flexDirection: "column" }}
        onClick={onPress}
      >
        <div
          className={
            "grid grid-cols-3  items-center  border-y-2 border-x-0 border-t-0 border-[#E5E5E5]  border-solid py-9 sm:py-3 sm:grid-cols-1 "
          }
        >
          <div className="text-xl text-black">{date_format}</div>
          <div className=" text-[24px] rounded-2xl text-[#183C72] flex gap-4 md:gap-1 md:py-2">
            {numArr?.map((item, index) => (
              <Ballinfo key={index} ball={item} isMegaBall={index === 5} />
            ))}
          </div>
          <div className="text-end text-[#183C72]  text-[28px] tb:text-2xl md:text-2xl font-bold md:text-center">
            {numberWithCommas(total_won_amount)}₮
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex justify-between text-black70 font-bold ">
          <p className="text-xl text-center md:text-[16px]">
            Хонжворын <br className="hidden md:block" /> хослол
          </p>
          <p className="text-xl text-center md:text-[16px]">
            Хожсон <br className="hidden md:block" /> тасалбарууд
          </p>
          <p className="text-xl text-center md:text-[16px]">
            Хожсон <br className="hidden md:block" /> мөнгөн дүн
          </p>
        </div>
        {DATA?.map((item, index) => (
          <PrizeInfo key={index} item={item} data={prize_info} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default PotBalls;
