import React from "react";

export function numberWithCommas(x) {
  return (+x)?.toLocaleString();
}

export function numberWithSpace(x) {
  return (+x)?.toLocaleString("en-US")?.replaceAll(",", " ");
}

export function areEqual(arr1, arr2) {
  let N = arr1.length;
  let M = arr2.length;

  // If lengths of arrays are not equal
  if (N !== M) return false;

  // Store arr1[] elements and their counts in
  // hash map
  let map = new Map();
  let count = 0;
  for (let i = 0; i < N; i++) {
    if (map.get(arr1[i]) == null) map.set(arr1[i], 1);
    else {
      count = map.get(arr1[i]);
      count++;
      map.set(arr1[i], count);
    }
  }

  // Traverse arr2[] elements and check if all
  // elements of arr2[] are present same number
  // of times or not.
  for (let i = 0; i < N; i++) {
    // If there is an element in arr2[], but
    // not in arr1[]
    if (!map.has(arr2[i])) return false;

    // If an element of arr2[] appears more
    // times than it appears in arr1[]
    if (map.get(arr2[i]) === 0) return false;

    count = map.get(arr2[i]);
    --count;
    map.set(arr2[i], count);
  }

  return true;
}

export function findCommonElements(arr1, arr2) {
  return arr1.some((item) => arr2.includes(item));
}

export const useCountdown = (s) => {
  const [time, setTime] = React.useState(s);

  React.useEffect(() => {
    if (s === 0) {
      return;
    }
    const interval = setInterval(() => {
      setTime((t) => {
        if (t === 0) {
          clearInterval(interval);
          return 0;
        }
        return t - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [s]);

  React.useEffect(() => {
    if (s) {
      setTime(s);
    }
  }, [s]);

  const pad = (n) => (n < 10 ? "0" : "") + n;

  const days = Math.floor(time / (60 * 60 * 24));
  const hoursLeft = Math.floor(time - days * 86400);
  const hours = Math.floor(hoursLeft / 3600);
  const minutesLeft = Math.floor(hoursLeft - hours * 3600);
  const minutes = Math.floor(minutesLeft / 60);
  const seconds = Math.floor(time % 60);

  return [
    `${pad(days)}`,
    `${pad(hours)}`,
    `${pad(minutes)}`,
    `${pad(seconds)}`,
  ];
};
