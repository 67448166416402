import { message } from "antd";
import axios from "axios";
import { config } from "config";

export const apiClient = axios.create({
  baseURL: config.apiBaseUrl,
  timeout: 65000,
});

export const setToken = (token) => {
  if (token) {
    apiClient.defaults.headers.common.Authorization = `Token ${token}`;
  }
};

export const getAccessToken = () => {
  return apiClient.defaults.headers.common.Authorization?.split(" ")[1];
};

export const setInterceptor = (fn = function () {}, efn = function () {}) => {
  apiClient.interceptors.response.use(fn, efn);
};

// apiClient.interceptors.response.use(
//   function (response) {
//     // let data = response?.data || {};
//     // if (data?.success && data?.sucmod && data?.message) {
//     //   message.success(data?.message);
//     // }
//     // if (!data?.success && data?.message) {
//     //   message.warning(data?.message);
//     // }
//     // if (!data?.success && data.reload) {
//     //   window.location.assign('/');
//     // } else {
//     //   return data;
//     // }
//   },
//   function (error, ress) {
//     message.error(error || 'Системийн алдаа');
//     return Promise.reject(error || 'Системийн алдаа');
//   },
// );
